import Lottie from 'react-lottie';

import animationData from '../../assets/animations/preloader.json';

type LoadingModalType = {
  open: boolean;
};

const LoadingModal = ({open}: LoadingModalType) => {
  const defaultConfig = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className={`fixed top-0 bg-offWhite left-0 w-screen h-screen ${
        open ? '' : 'hidden'
      }`}>
      <div
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
          open ? '' : 'hidden'
        }`}
        style={{backgroundColor: 'white', padding: 20, borderRadius: 10}}>
        <Lottie options={defaultConfig} height={100} width={100} />
      </div>
    </div>
  );
};

export default LoadingModal;
