import {ApolloError} from '@apollo/client';
import {toast} from '@cashiaApp/web-components';
import React, {createContext, useContext, useState, useCallback} from 'react';
import {NavigateFunction} from 'react-router-dom';

import {
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
} from '../../../graphql/generated';

interface EmailVerificationContextType {
  isVerifying: boolean;
  isResending: boolean;
  isVerificationFailed: boolean;
  cooldownTime: number;
  verifyEmail: (
    token: string,
    email: string | null,
    navigate: NavigateFunction
  ) => Promise<void>;
  resendVerificationEmail: (
    email: string,
    navigate: NavigateFunction
  ) => Promise<boolean>;
  setIsVerificationFailed: React.Dispatch<React.SetStateAction<boolean>>;
  setCooldownTime: React.Dispatch<React.SetStateAction<number>>;
}

const EmailVerificationContext = createContext<
  EmailVerificationContextType | undefined
>(undefined);

export const VERIFICATION_MESSAGES = {
  VERIFICATION_SUCCESS:
    'Your email was verified successfully. You may now log in',
  EMAIL_ALREADY_VERIFIED:
    'This email has already been verified. Please log in.',
  RESEND_SUCCESS: 'Verification email sent successfully!',
  TOKEN_EXPIRED:
    'This email verification link has expired. Please resend verification email',
  INVALID_TOKEN: 'This verification link is invalid. Please request a new one.',
  RECORD_NOT_FOUND:
    'No account found for this verification link. Please sign up first.',
  DEFAULT:
    'There was a problem with your email verification. Please contact support',
};

export const EmailVerificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({children}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);

  const [verifyEmailMutation] = useVerifyEmailMutation();
  const [resendVerificationMutation] = useResendEmailVerificationMutation();

  const verifyEmail = useCallback(
    async (
      token: string,
      email: string | null,
      navigate: NavigateFunction
    ): Promise<void> => {
      setIsVerifying(true);
      try {
        if (!token) {
          const message = VERIFICATION_MESSAGES.INVALID_TOKEN;
          navigate(email ? '/email-verification' : '/login', {
            state: email
              ? {
                  email,
                  isVerificationFailed: true,
                  verificationMessage: message,
                }
              : {
                  verificationStatus: 'error',
                  message,
                },
          });
          return;
        }

        const {data} = await verifyEmailMutation({
          variables: {token},
        });

        if (data?.verifyEmail) {
          navigate('/login', {
            state: {
              verificationStatus: 'success',
              message: VERIFICATION_MESSAGES.VERIFICATION_SUCCESS,
            },
          });
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = error.message.toLowerCase();
          let message = VERIFICATION_MESSAGES.DEFAULT;
          let navigationState = {};

          switch (true) {
            case errorMessage.includes('already verified'):
              message = VERIFICATION_MESSAGES.EMAIL_ALREADY_VERIFIED;
              navigationState = {
                verificationStatus: 'already-verified',
                message,
              };
              navigate('/login', {state: navigationState});
              break;

            case errorMessage.includes('token expired') ||
              errorMessage.includes('otp expired'):
              message = VERIFICATION_MESSAGES.TOKEN_EXPIRED;
              navigationState = email
                ? {
                    email,
                    isVerificationFailed: true,
                    verificationMessage: message,
                  }
                : {
                    verificationStatus: 'error',
                    message,
                  };
              navigate(email ? '/email-verification' : '/login', {
                state: navigationState,
              });
              break;

            case errorMessage.includes('invalid token'):
              message = VERIFICATION_MESSAGES.INVALID_TOKEN;
              navigationState = email
                ? {
                    email,
                    isVerificationFailed: true,
                    verificationMessage: message,
                  }
                : {
                    verificationStatus: 'error',
                    message,
                  };
              navigate(email ? '/email-verification' : '/login', {
                state: navigationState,
              });
              break;

            case errorMessage.includes('not found'):
              message = VERIFICATION_MESSAGES.RECORD_NOT_FOUND;
              navigationState = {
                verificationStatus: 'error',
                message,
              };
              navigate('/login', {state: navigationState});
              break;

            default:
              navigationState = email
                ? {
                    email,
                    isVerificationFailed: true,
                    verificationMessage: message,
                  }
                : {
                    verificationStatus: 'error',
                    message,
                  };
              navigate(email ? '/email-verification' : '/login', {
                state: navigationState,
              });
          }
        }
      } finally {
        setIsVerifying(false);
      }
    },
    [verifyEmailMutation]
  );

  const resendVerificationEmail = useCallback(
    async (email: string, navigate: NavigateFunction): Promise<boolean> => {
      setIsResending(true);
      try {
        const {data} = await resendVerificationMutation({
          variables: {email},
        });
        if (data?.resendEmailVerification) {
          return true;
        }
        return false;
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = error.message.toLowerCase();
          if (errorMessage.includes('already verified')) {
            navigate('/login', {
              state: {
                verificationStatus: 'already-verified',
                message: VERIFICATION_MESSAGES.EMAIL_ALREADY_VERIFIED,
              },
            });
          } else {
            toast.error(error.message);
          }
        }
        throw error;
      } finally {
        setIsResending(false);
      }
    },
    [resendVerificationMutation]
  );

  const value: EmailVerificationContextType = {
    isVerifying,
    isResending,
    isVerificationFailed,
    cooldownTime,
    verifyEmail,
    resendVerificationEmail,
    setIsVerificationFailed,
    setCooldownTime,
  };

  return (
    <EmailVerificationContext.Provider value={value}>
      {children}
    </EmailVerificationContext.Provider>
  );
};

export const useEmailVerificationContext = () => {
  const context = useContext(EmailVerificationContext);
  if (context === undefined) {
    throw new Error(
      'useEmailVerificationContext must be used within a EmailVerificationProvider'
    );
  }
  return context;
};
