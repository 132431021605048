import {useLocation, useNavigate} from 'react-router-dom';

import {cn} from '../../../utils/reusableFunctions';

export type Tab = {
  name: string;
  count?: number;
  link: string;
  navState?: {name: string; id: string};
};

type Props = {
  tabs: Tab[];
  rightElement?: JSX.Element;
  tabContainerStyles?: string;
  tabLabelStyles?: string;
};

const Tabs = ({
  tabs,
  rightElement,
  tabContainerStyles,
  tabLabelStyles,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  return (
    <div className={cn('relative flex w-full', tabContainerStyles)}>
      <ul className="z-10 flex w-full gap-12 overflow-x-scroll">
        {tabs.map((tab, i) => (
          <li
            key={i}
            className={cn(
              'cursor-pointer shrink-0 py-2 sm:text-[15px] md:text-[16px] font-medium transition-colors duration-300 ease-in-out',
              {
                'border-cashiaBlue border-b-4 font-semibold': isActive(
                  tab.link
                ),
              },
              tabLabelStyles
            )}
            onClick={() => navigate(tab.link, {state: tab.navState})}>
            <p
              className={cn('text-foggy', {
                'text-cashiaBlue': isActive(tab.link),
              })}>
              {tab.name}
            </p>
          </li>
        ))}
        {rightElement && (
          <li className="ml-auto mr-3 cursor-pointer self-center py-2">
            {rightElement}
          </li>
        )}
      </ul>
      <div className="absolute bottom-0 z-0 h-[2px] w-full bg-[#D0CECD66]" />
    </div>
  );
};

export default Tabs;
