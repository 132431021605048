import {Input, Label} from '@cashiaApp/web-components';

import {ReactComponent as FacebookIcon} from '../../../assets/icons/social/facebook.svg';
import {ReactComponent as InstagramIcon} from '../../../assets/icons/social/instagram.svg';
import {ReactComponent as SnapchatIcon} from '../../../assets/icons/social/snapchat.svg';
import InputInfo from '../components/InputInfo';
import {useBusinessProfileContentContext} from '../layout';

const SocialMedia = () => {
  const {data} = useBusinessProfileContentContext();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="font-semibold sm:text-[18px] md:text-[24px]">
        Social Media
      </h2>
      <InputInfo
        label="Website"
        value={data?.businessOnboarding?.socialMedia?.website || ''}
      />
      <div className="flex flex-col gap-2 sm:w-full md:w-[520px]">
        <Label className="sm:font-medium md:font-semibold sm:text-[15px] md:text-[16px]">
          Social Media
        </Label>
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-col">
            <div className="relative w-full">
              <InstagramIcon className="absolute left-3 top-5" />
              <Input
                className="pl-10 disabled:cursor-default disabled:opacity-100 text-[16px] font-medium"
                value={data?.businessOnboarding?.socialMedia?.instagram || ''}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative w-full">
              <FacebookIcon className="absolute left-3 top-5" />
              <Input
                className="pl-10 disabled:cursor-default disabled:opacity-100 text-[16px] font-medium"
                value={data?.businessOnboarding?.socialMedia?.facebook || ''}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative w-full">
              <SnapchatIcon className="absolute left-3 top-5" />
              <Input
                className="pl-10 disabled:cursor-default disabled:opacity-100 text-[16px] font-medium"
                value={data?.businessOnboarding?.socialMedia?.snapchat || ''}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
