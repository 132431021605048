import React from 'react';

const ToBeWorkedOn = () => {
  return (
    <div className="flex flex-col gap-1 h-full w-full pt-48 items-center">
      <p className="text-2xl font-bold">TO BE</p>
      <p className="text-2xl font-bold">WORKED ON</p>
    </div>
  );
};

export default ToBeWorkedOn;
