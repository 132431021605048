import {Button, toast} from '@cashiaApp/web-components';
import React, {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {
  useEmailVerificationContext,
  VERIFICATION_MESSAGES,
} from './context/EmailVerificationContext';
import failed from '../../assets/icons/info-circle-pink.svg';
import smsIcon from '../../assets/icons/sms_Icon_p.svg';
import LogoSvg from '../../assets/logos/cashia_logo_pink.svg';
import Spinner from '../../components/tailwind/Spinner';

const COOLDOWN_DURATION = 60;

const VerificationLoader = () => (
  <div className="flex flex-col min-h-screen items-center justify-center">
    <Spinner className="w-8 h-8" />
    <p className="mt-4 text-darkGrey">Verifying your email...</p>
  </div>
);

interface LocationState {
  email?: string;
  fromLogin?: boolean;
  isVerificationFailed?: boolean;
  verificationMessage?: string;
}

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isVerifying,
    isResending,
    isVerificationFailed,
    cooldownTime,
    verifyEmail,
    resendVerificationEmail,
    setIsVerificationFailed,
    setCooldownTime,
  } = useEmailVerificationContext();

  const emailFromUrl = searchParams.get('email');
  const {
    email: emailFromState,
    isVerificationFailed: failedFromState,
    fromLogin,
  } = (location.state as LocationState) || {};
  const email = emailFromState || emailFromUrl;
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      void verifyEmail(token, email, navigate);
    }
  }, [token, email, verifyEmail, navigate]);

  useEffect(() => {
    if (failedFromState) {
      setIsVerificationFailed(true);
    } else {
      setIsVerificationFailed(false);
    }
  }, [failedFromState, setIsVerificationFailed]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [cooldownTime, setCooldownTime]);

  useEffect(() => {
    if (!email && !token) {
      navigate('/signup');
    }
  }, [email, token, navigate]);

  const handleResendVerification = async () => {
    if (!email) {
      toast.error('Email address not found. Please sign up again.');
      navigate('/signup');
      return;
    }

    if (cooldownTime > 0) return;

    try {
      const success = await resendVerificationEmail(email, navigate);
      if (success) {
        toast.success(VERIFICATION_MESSAGES.RESEND_SUCCESS);
        setCooldownTime(COOLDOWN_DURATION);
        if (fromLogin || isVerificationFailed) {
          setIsVerificationFailed(false);
        }
      }
    } catch (error) {
      console.error('Error in handleResendVerification:', error);
    }
  };

  const formatCooldownTime = (seconds: number): string => `${seconds}s`;

  if (token && isVerifying) return <VerificationLoader />;
  if (!email) return null;

  return (
    <div className="flex flex-col min-h-screen justify-between items-center">
      <div className="hidden md:flex py-12 w-full px-10">
        <img
          src={LogoSvg}
          alt="logo"
          className="xl:w-[150px] xl:h-[50px] w-[120px] h-[40px]"
        />
      </div>
      <div className="flex flex-col items-center py-52 lg:py-0 justify-center text-center gap-4 px-4 w-full max-w-xl mb-6">
        <div className="flex justify-center">
          <img
            src={isVerificationFailed ? failed : smsIcon}
            alt={isVerificationFailed ? 'failed verification' : 'verification'}
            className="w-12 h-12 md:w-16 md:h-16"
          />
        </div>
        <p className="font-metropolis text-black font-bold text-xl md:text-4xl">
          {isVerificationFailed ? 'Verification Failed' : 'Verify your Email'}
        </p>
        <div className="flex flex-col gap-2 w-[95%] text-center">
          {isVerificationFailed ? (
            <p className="font-Metropolis text-darkGrey font-medium text-sm px-4 md:px-0">
              Unfortunately, it seems the link you clicked has either expired or
              failed to process correctly. To resolve this, please click the{' '}
              <span className="font-semibold text-sm text-black">
                'Resend Verification Link'
              </span>{' '}
              button below to request a new verification link. If you continue
              to experience any issues, don't hesitate to reach out to our
              support team for further assistance.
            </p>
          ) : (
            <p className="font-Metropolis text-darkGrey font-medium text-sm px-4 md:px-0">
              We've sent you an email to{' '}
              <span className="font-semibold text-sm text-cashiaBlue">
                {email}
              </span>
              . Simply click the link to verify your account. If your inbox is
              still empty, take a look in your spam folder. If you still haven't
              received it, please double check that you entered the correct
              email address.
            </p>
          )}
        </div>
        <Button
          type="button"
          onClick={handleResendVerification}
          disabled={isResending || cooldownTime > 0}
          className="bg-smoothRed text-white w-full max-w-xs md:max-w-md h-10 my-6">
          {isResending
            ? 'Sending...'
            : cooldownTime > 0
            ? `Resend link in ${formatCooldownTime(cooldownTime)}`
            : 'Resend Verification Link'}
        </Button>
      </div>
      <p className="text-foggy font-metropolis text-base lg:text-sm py-10">
        © {new Date().getFullYear()} Cashia Limited. All Rights Reserved.
      </p>
    </div>
  );
};

export default EmailVerification;
