import {Button, Textarea, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo} from 'react';

import FormInput from './FormInput';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as Info} from '../../../assets/icons/info-circle.svg';
import PlacesAutocomplete from '../../../components/common/AddressInput';
import CustomPhoneInput from '../../../components/common/CustomPhoneInput';
import DropDown from '../../../components/common/DropDown';
import Spinner from '../../../components/tailwind/Spinner';
import {useGetBusinessCategoriesQuery} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import validateKenyanPhoneNumber from '../../../utils/validatePhoneNumber';
import {useSelfOnboardingContentContext} from '../layout';

export interface BussinessInfoForm {
  businessName?: string;
  category?: string;
  description?: string;
  phoneNumber?: {
    countryCode: string;
    number: string;
  };
  location?: string;
  building?: string;
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: BussinessInfoForm) => FormErrors;

const validate: ValidateFunction = (data: BussinessInfoForm) => {
  const errors: FormErrors = {};
  if (!data?.businessName?.trim()) {
    errors.businessName = 'Business name is required';
  }
  if (!data?.category?.trim()) {
    errors.category = 'Category is required';
  }
  if (!data?.description?.trim()) {
    errors.description = 'Description is required';
  }
  if (!data?.phoneNumber?.countryCode?.trim()) {
    errors.phoneNumber = 'Country code is required';
  }
  if (!data?.phoneNumber?.number?.trim()) {
    errors.phoneNumber = 'Phone number is required';
  } else if (
    !validateKenyanPhoneNumber(data?.phoneNumber?.number?.trim() || '')
  ) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  if (!data?.location?.trim()) {
    errors.location = 'Location is required';
  }

  return errors;
};

export default function BusinessInfo() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [data, setData] = React.useState<BussinessInfoForm>({
    phoneNumber: {
      countryCode: '+254',
      number: '',
    },
  });
  const [errors, setErrors] = React.useState<FormErrors>({});
  const {update, onboardingData, updateLoading} =
    useSelfOnboardingContentContext();
  const {data: businessCategoriesData} = useGetBusinessCategoriesQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const businessCategories = useMemo(() => {
    const copy = [...(businessCategoriesData?.businessCategories || [])];
    return copy.sort((a, b) => a?.name?.localeCompare(b?.name));
  }, [businessCategoriesData]);

  useEffect(() => {
    if (onboardingData && onboardingData.buisnessInfo) {
      setData({...onboardingData.buisnessInfo});
    }
  }, [onboardingData]);

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (btnDisabled) return;
    setErrors({});
    const validationErrors = validate(data);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      update?.({...onboardingData, buisnessInfo: {...data}});
      toast.success('Business info captured!');
    }
  };

  const btnDisabled = useMemo(() => {
    return (
      !data?.businessName ||
      !data?.category ||
      !data?.description ||
      !data?.phoneNumber?.number ||
      !data?.phoneNumber?.countryCode ||
      !data?.location
    );
  }, [data]);

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return (
        <div className="flex items-center h-[24px]">
          <Info />
          <span className="text-orangeDark mx-2 p-1 rounded text-[12px] font-normal bg-lightOrange">
            {errors[fieldName]}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex w-full h-full max-md:h-[95%] overflow-y-scroll">
      <form className="flex flex-col justify-between overflow-x-hidden">
        <div>
          <p className="font-[600]">Step 1:</p>
          <p className="mb-3 font-[600] text-[40px] leading-[40px] max-md:text-[24px] max-md:leading-[24px]">
            Business Information
          </p>

          <FormInput
            label="Business Name"
            name="businessName"
            onChange={(e) => setData({...data, businessName: e})}
            value={data.businessName}
            error={renderErrorMessage('businessName')}
            required
          />

          <div className="mb-3">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Category * {renderErrorMessage('category')}
            </p>
            <DropDown
              dropDownPosition="center"
              show={dropdownOpen}
              setShow={setDropdownOpen}
              actionElement={
                <div
                  id="category-dropdown"
                  className={`${
                    dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                  } px-[10px] flex flex-row items-center justify-between max-md:w-[86vw] w-[497px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                  <p className="w-[450px] overflow-hidden truncate text-[14px]">
                    {businessCategories.find((x) => x.id === data?.category)
                      ?.name || ''}
                  </p>
                  <DropdownIcon />
                </div>
              }>
              <div className="shadow-sm cursor-pointer max-md:w-[88vw] w-[497px] h-[412px] overflow-y-scroll ">
                {businessCategories.map((category) => (
                  <div
                    onClick={() => {
                      setDropdownOpen(false);
                      setData({...data, category: category.id});
                    }}
                    key={category.id}
                    id="category"
                    className="hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                    <p className="text-[14px] font-[500]">{category.name}</p>
                  </div>
                ))}
              </div>
            </DropDown>
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Description * {renderErrorMessage('description')}
            </p>
            <Textarea
              rows={2}
              maxLength={100}
              value={data.description}
              className="min-h-[90px] max-md:w-[86vw] w-[497px] bg-transparent border-[1px] border-dividerGrey h-auto focus:outline-none focus:ring-0 focus:border-red-300"
              onChange={(e) => setData({...data, description: e.target.value})}
              name="description"
            />
            <div className="relative bottom-6 max-md:left-[55vw] left-[360px] pl-5 w-[120px]">
              <p className="font-[500] text-[10px] text-foggy">
                {data.description?.length || 0} / 100 characters
              </p>
            </div>
          </div>
          <div className="mb-3">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Phone Number * {renderErrorMessage('phoneNumber')}
            </p>
            <CustomPhoneInput
              countryCode={data.phoneNumber?.countryCode || ''}
              phoneNumber={data.phoneNumber?.number || ''}
              onChange={(countryCode: string, number: string) =>
                setData({...data, phoneNumber: {countryCode, number}})
              }
            />
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Location * {renderErrorMessage('location')}
            </p>
            <PlacesAutocomplete
              onSelect={(value) => setData({...data, location: value})}
              defaultValue={data.location}
            />
          </div>

          <FormInput
            label="Complex / Building Name"
            onChange={(e) => setData({...data, building: e})}
            value={data.building}
          />
        </div>
        <div className="flex flex-row justify-between pb-6">
          <div />
          <Button
            onClick={(e) => handleFormSubmit(e)}
            className={cn(
              'max-md:w-[143px] w-[237px] h-[48px] rounded-[8px] flex items-center justify-center font-[600] bg-pink text-white',
              {
                'text-disabledText bg-lightGrey cursor-default': btnDisabled,
              }
            )}>
            {updateLoading ? <Spinner className="fill-white" /> : 'Next'}
          </Button>
        </div>
      </form>
    </div>
  );
}
