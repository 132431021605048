import {Button, Input, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';

import {ReactComponent as Facebook} from '../../../assets/icons/facebook_pink.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/info-circle.svg';
import {ReactComponent as Instagram} from '../../../assets/icons/instagram_pink.svg';
import {ReactComponent as Snapchat} from '../../../assets/icons/snapchat_pink.svg';
import Spinner from '../../../components/tailwind/Spinner';
import {BusinessOnboardingSocialMediaInput} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import {useSelfOnboardingContentContext} from '../layout';

const SocialMedia = () => {
  const [data, setData] = useState<BusinessOnboardingSocialMediaInput>();
  const [WebsiteError, setWebsiteError] = useState('');
  const [InstaError, setInstaError] = useState('');
  const [FbError, setFbError] = useState('');
  const [SnapError, setSnapError] = useState('');
  const {update, onboardingData, goBack, updateLoading} =
    useSelfOnboardingContentContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (onboardingData && onboardingData.socialMedia) {
      setData({...onboardingData.socialMedia});
    }
  }, [onboardingData]);

  const handleWebsiteChange = (inputValue: string) => {
    setData({...data, website: inputValue});
    const urlPattern =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    const invalidCharactersPattern = /[ !@#]/;

    if (!inputValue) {
      setWebsiteError('');
      return;
    }
    if (!urlPattern.test(inputValue)) {
      setWebsiteError('Please enter a valid Website address.');
    } else if (invalidCharactersPattern.test(inputValue)) {
      setWebsiteError(
        'Please enter a valid Website URL without spaces or special characters like !, @, #.'
      );
    } else {
      setWebsiteError('');
    }
  };

  const handleInstaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, instagram: e.target.value});

    if (!inputValue) {
      setInstaError('');
      return;
    }
    if (inputValue.length > maxLength) {
      setInstaError(
        'The Instagram URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const instagramUrlRegex =
      /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9._]+)\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (inputValue == '') {
      setInstaError('');
    }

    if (
      !instagramUrlRegex.test(inputValue) ||
      invalidCharsPattern.test(inputValue)
    ) {
      setInstaError(
        'Please enter a valid Instagram URL or handle without spaces or invalid characters.'
      );
    } else {
      setInstaError('');
    }
  };
  const handleFbChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, facebook: e.target?.value});

    if (!inputValue) {
      setFbError('');
    }
    if (inputValue.length > maxLength) {
      setFbError(
        'The Facebook URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const facebookUrlRegex =
      /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{5,}\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (
      inputValue &&
      (!facebookUrlRegex.test(inputValue) ||
        invalidCharsPattern.test(inputValue))
    ) {
      setFbError(
        'Please enter a valid Facebook URL or handle without spaces or invalid characters.'
      );
    } else {
      setFbError('');
    }
  };
  const handleSnapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, snapchat: e.target.value});
    if (!inputValue) {
      setSnapError('');
    }
    if (inputValue.length > maxLength) {
      setSnapError(
        'The Snapchat URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const snapchatUrlRegex =
      /^(https?:\/\/)?(www\.)?snapchat\.com\/add\/[a-zA-Z0-9._-]+\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (
      inputValue &&
      (!snapchatUrlRegex.test(inputValue) ||
        invalidCharsPattern.test(inputValue))
    ) {
      setSnapError(
        'Please enter a valid Snapchat URL or handle without spaces or invalid characters.'
      );
    } else {
      setSnapError('');
    }
  };

  const error = useMemo(
    () => InstaError || FbError || SnapError || WebsiteError,
    [InstaError, FbError, SnapError, WebsiteError]
  );

  const isDisabled = useMemo(() => {
    return !!error;
  }, [error]);

  return (
    <div className="flex w-full h-[98%] max-md:h-[93%] overflow-y-scroll">
      <div className="flex flex-col justify-between">
        <div>
          <div>
            <p className="text-black text-sm font-semibold">Step 4:</p>
            <p className="mb-3 font-[600] text-[40px] leading-[40px] max-md:text-[24px] max-md:leading-[24px]">
              Social Media
            </p>
          </div>

          <div>
            <div className="mb-2">
              <div className="flex items-center">
                <p className="text-base font-semibold text-black mb-1 pr-5">
                  Website
                </p>
                {WebsiteError && (
                  <div className="flex items-center border rounded-lg p-1 bg-lightOrange mb-2">
                    <ErrorIcon className="pr-1 " />
                    <p className="text-red-500 text-[10px] font-medium items-center w-[200px]">
                      {WebsiteError}
                    </p>
                  </div>
                )}
              </div>

              <Input
                className="w-[497px] max-md:w-[86vw] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                value={data?.website || undefined}
                name="website"
                onChange={(e) => handleWebsiteChange(e.target.value)}
              />
            </div>
            <div className="mb-7 mt-5">
              <div className="flex items-center">
                <p className="text-base font-semibold text-black mb-1 pr-3">
                  Social Media
                </p>

                {(InstaError || FbError || SnapError) && (
                  <div className="flex items-center border rounded-lg p-1 mb-1 bg-lightOrange w-[55%]">
                    <ErrorIcon className="pr-1" />
                    <p className="text-red-500 text-[10px] font-medium w-full">
                      {error}
                    </p>
                  </div>
                )}
              </div>
              <div className="mb-4">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Instagram />
                  </div>
                  <Input
                    className="w-[497px] max-md:w-[86vw] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                    value={data?.instagram || undefined}
                    name="instagram"
                    onChange={handleInstaChange}
                  />
                </div>
              </div>

              <div className="mb-4">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Facebook />
                  </div>
                  <Input
                    className="w-[497px] max-md:w-[86vw] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                    value={data?.facebook || undefined}
                    name="fb"
                    onChange={handleFbChange}
                  />
                </div>
              </div>

              <div className="mb-4">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Snapchat />
                  </div>
                  <Input
                    className="w-[497px] max-md:w-[86vw] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                    value={data?.snapchat || undefined}
                    name="snapchat"
                    onChange={handleSnapChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-10 w-full pb-6">
          <Button
            className="cursor-pointer max-md:w-[143px] w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white"
            onClick={goBack}>
            <p className="text-active-red font-[600]">Previous</p>
          </Button>
          <Button
            className={cn(
              'max-md:w-[143px] w-[237px] h-[48px] rounded-[8px] flex items-center justify-center font-[600] bg-pink text-white',
              {
                'text-disabledText bg-lightGrey cursor-default': isDisabled,
              }
            )}
            onClick={() => {
              if (isDisabled) return;
              update?.({...onboardingData, socialMedia: {...data}}, true);
              toast.success(
                'Bussiness profile information successfully captured'
              );
            }}>
            {updateLoading ? <Spinner className="fill-white" /> : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
