import {Label, Textarea} from '@cashiaApp/web-components';

import InputInfo from '../components/InputInfo';
import {useBusinessProfileContentContext} from '../layout';

const BusinessInformation = () => {
  const {data} = useBusinessProfileContentContext();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="font-semibold sm:text-[18px] md:text-[24px]">
        Business Information
      </h2>
      <InputInfo label="Business Name" value={data?.businessOnboarding?.name} />
      <InputInfo
        label="Business Category"
        value={`${data?.businessOnboarding?.category?.code || ''} ${
          data?.businessOnboarding?.category?.name || ''
        }`}
      />
      <div className="flex flex-col gap-3 sm:w-full md:w-[520px]">
        <Label className="sm:font-medium md:font-semibold sm:text-[15px] md:text-[16px]">
          Business Description
        </Label>
        <Textarea
          className="disabled:cursor-default disabled:opacity-100 text-sm font-medium"
          value={data?.businessOnboarding?.description || ''}
          disabled
        />
      </div>
      <InputInfo
        label="Business Phone Number"
        value={`${data?.businessOnboarding?.phoneNumber?.countryCode || ''} ${
          data?.businessOnboarding?.phoneNumber?.number || ''
        }`}
      />
      <InputInfo
        label="Business Address"
        value={data?.businessOnboarding?.address?.location.formattedAddress}
      />
      <InputInfo
        label="Complex/ Building Name"
        value={data?.businessOnboarding?.address?.buildingName}
      />
    </div>
  );
};

export default BusinessInformation;
