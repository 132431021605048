import {toast} from '@cashiaApp/web-components';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

interface LocationState {
  verificationStatus?: string;
  message?: string;
}
export const useVerificationMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isToastShown, setIsToastShown] = useState(false);

  useEffect(() => {
    const state = location.state as LocationState;
    if (state?.message && !isToastShown) {
      switch (state.verificationStatus) {
        case 'success':
          toast.success(state.message);
          break;
        case 'already-verified':
          toast.info(state.message);
          break;
        case 'error':
          toast.error(state.message);
          break;
      }
      setIsToastShown(true);
      navigate(location.pathname, {replace: true});
    }
  }, [location, navigate, isToastShown]);
};
