import React from 'react';

import InputErrorMessage from '../../../components/common/InputErrorMessage';
import FormInput from '../../links/Components/FormInput';

export default function MPesaPay({
  setPhoneNumber,
  errorMessage,
  phoneNumber,
}: {
  setPhoneNumber: (val: string) => void;
  errorMessage?: string;
  phoneNumber?: string;
}) {
  return (
    <div className="mt-6 max-md:mb-6">
      <FormInput
        className="w-full pl-[55px]"
        label="Enter phone number"
        placeholder="712 345 678"
        onChange={setPhoneNumber}
        value={phoneNumber}
        leftElement={
          <div>
            <p className="font-[600]">+254</p>
          </div>
        }
        error={<InputErrorMessage errorMessage={errorMessage} />}
      />
    </div>
  );
}
