import {Button, useMediaQuery} from '@cashiaApp/web-components';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import NoLinks from './NoLinks';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as ActiveIcon} from '../../../assets/icons/row-link-active.svg';
import Paginator from '../../../components/common/Paginator';
import CommonTable from '../../../components/common/table';
import Spinner from '../../../components/tailwind/Spinner';
import {CursorInput, useGetPaymentLinksQuery} from '../../../graphql/generated';
import {OutletContextProps} from '../../../layouts/MainLayout';
import Tabs from '../../businessProfile/components/Tabs';

type Props = {
  status?: 'all' | 'Active' | 'Deactivated';
};

type RowData = {
  id: string;
  Title: {
    image: JSX.Element;
    text: string;
  };
  'Date created': string;
  status: JSX.Element;
  amount: string;
};

export default function Links({status}: Props) {
  const navigate = useNavigate();
  const [data, setData] = useState<RowData[]>([]);
  const [count, setCount] = useState<number>(1);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const {
    data: linksData,
    loading: linksLoading,
    refetch,
  } = useGetPaymentLinksQuery({
    variables: {
      input: {
        cursor: {
          first: 10,
        },
      },
    },
  });

  const {setHeaderComponent} = useOutletContext<OutletContextProps>();
  useLayoutEffect(() => {
    if (!linksData?.paymentLinks?.pageInfo?.entries) return;
    setHeaderComponent(() => (
      <div className="h-[80px] max-md:h-[50px] flex items-end">
        <Tabs
          tabs={[
            {
              name: `All (${linksData?.paymentLinks.pageInfo.entries || 0})`,
              link: '/payment-links',
            },
            {
              name: `Active (${linksData?.paymentLinks.pageInfo.entries || 0})`,
              link: '/payment-links/links/active',
            },
            {
              name: 'Deactivated (0)',
              link: '/payment-links/links/deactivated',
            },
          ]}
        />
      </div>
    ));
    return () => {
      setHeaderComponent(undefined);
    };
  }, [setHeaderComponent, navigate, linksData]);

  useEffect(() => {
    if (!linksData?.paymentLinks?.edges) return;
    setData(
      linksData?.paymentLinks?.edges?.map((edge) => ({
        id: edge.node.id,
        Title: {
          text: edge.node.title,
          image: <ActiveIcon />,
        },
        'Date created': format(
          new Date((edge.node.createdAt as string) || 0),
          'PPP'
        ),
        amount:
          edge.node.cost.currencyCode +
          ' ' +
          new Intl.NumberFormat('en-US').format(
            Number(edge.node.cost.amountInCents / 1000)
          ),
        status: (
          <div>
            <p className="text-green-400">Active</p>
          </div>
        ),
      }))
    );
  }, [linksData]);

  const loadMore = useCallback(
    async (next: boolean) => {
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after = linksData?.paymentLinks.pageInfo.endCursor;
        cursorLoad.first = 10;
      } else {
        cursorLoad.before = linksData?.paymentLinks.pageInfo.startCursor;
        cursorLoad.last = 10;
      }
      const res = await refetch({
        input: {cursor: cursorLoad},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count > 0 ? count - 1 : 0);
      }
    },
    [linksData, count, refetch]
  );

  useEffect(() => {
    setCount(1);
  }, [status]);

  const navToLinkDetails = (row: {id?: string}) =>
    navigate('/payment-links/link-details', {state: {link: row.id}});

  if (linksLoading) {
    return <Spinner className="mt-52 size-10 self-center w-full" />;
  }
  if (!linksData?.paymentLinks?.pageInfo?.entries) {
    return <NoLinks />;
  }

  return (
    <div className="overflow-y-scroll h-full">
      <div className="w-full flex justify-between items-center my-8 max-md:my-5">
        <p className="font-[700] text-[34px] max-md:text-[20px]">
          Payment links
        </p>
        <Button
          onClick={() => navigate('/payment-links/create-link')}
          className="max-md:bottom-[30px] max-md:w-[92vw] w-[217px] max-md:w-[75px] max-md:rounded-[24px] max-md:text-[13px] max-md:h-[30px] h-[48px] bg-pink">
          {isDesktop ? 'Create a payment link +' : 'Add +'}
        </Button>
      </div>
      {isDesktop ? (
        <div>
          <CommonTable
            columns={['Title', 'Date created', 'amount', 'status']}
            data={data}
            onSelect={navToLinkDetails}
          />
        </div>
      ) : (
        <div>
          {data.map((item) => (
            <div
              key={item.id}
              onClick={() => navToLinkDetails(item)}
              className="flex items-center justify-between border-b-[1px] border-greyish py-[10px] w-full">
              <div className="flex items-center gap-5 flex-1 min-w-0">
                <ActiveIcon className="min-w-[34px]" />
                <div className="min-w-0">
                  <p className="text-[14px] font-[500] break-words overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.Title.text}
                  </p>
                  <p className="text-foggy flex gap-1 text-[13px]">
                    {item.amount} -
                    <span className="text-green-400 font-[500]">
                      {item.status}
                    </span>
                  </p>
                </div>
              </div>
              <ArrowIcon />
            </div>
          ))}
        </div>
      )}
      <div className="mt-10 mb-8">
        {linksData?.paymentLinks?.pageInfo?.entries > 10 && (
          <Paginator
            count={count}
            pageInfo={linksData?.paymentLinks.pageInfo}
            loadMore={loadMore}
          />
        )}
      </div>
    </div>
  );
}
