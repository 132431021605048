import InputInfo from '../components/InputInfo';
import {useBusinessProfileContentContext} from '../layout';

const BankAccountDetails = () => {
  const {data} = useBusinessProfileContentContext();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="font-semibold sm:text-[18px] md:text-[24px]">
        Bank Account Details
      </h2>
      <InputInfo
        label="Bank Name"
        value={data?.businessOnboarding?.bankAccountBank?.name}
      />
      <InputInfo
        label="Account Holder Name"
        value={data?.businessOnboarding?.bankAccountHolder}
      />
      <InputInfo
        label="Account Number"
        value={data?.businessOnboarding?.bankAccountNumber}
      />
    </div>
  );
};

export default BankAccountDetails;
