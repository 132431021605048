import {useMediaQuery} from '@cashiaApp/web-components';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import {ReactComponent as MastercardIcon} from '../../../assets/icons/MasterCard.svg';
import {ReactComponent as MpesaIcon} from '../../../assets/icons/MpesaIcon.svg';
import {ReactComponent as VisaCardIcon} from '../../../assets/icons/VisaCard.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as EmptyIcon} from '../../../assets/icons/empty-sad-face.svg';
import {ReactComponent as ActiveIcon} from '../../../assets/icons/row-link-active.svg';
import {ReactComponent as ProfileIcon} from '../../../assets/icons/user-square.svg';
import Paginator from '../../../components/common/Paginator';
import CommonTable from '../../../components/common/table';
import Spinner from '../../../components/tailwind/Spinner';
import {CursorInput, useGetOrdersQuery} from '../../../graphql/generated';
import {OutletContextProps} from '../../../layouts/MainLayout';
import Tabs from '../../businessProfile/components/Tabs';

type Props = {
  status?: 'all' | 'successful' | 'pending' | 'failed';
};

type RowData = {
  id: string;
  Name: {
    image: JSX.Element;
    text: string;
    text2: string;
  };
  'Payment Method': {
    image: JSX.Element;
    text: string;
  };
  'Payment Date': string;
  'Transaction Id': string;
  amount: string;
  status: JSX.Element;
};

function getPaymentIcon(method: string) {
  switch (method) {
    case 'Visa Card':
      return <VisaCardIcon />;
    case 'Master Card':
      return <MastercardIcon />;
    default:
      return <MpesaIcon />;
  }
}

function getStatusColor(status: string) {
  switch (status) {
    case 'SUCCESSFUL':
      return 'text-brightGreen';
    case 'PENDING':
      return 'text-continousYellow';
    case 'FAILED':
      return 'text-brightRed';
    default:
      return 'text-gray-500';
  }
}

function Payments({status}: Props) {
  const navigate = useNavigate();
  const [data, setData] = useState<RowData[]>([]);
  const [count, setCount] = useState<number>(1);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const {setHeaderComponent} = useOutletContext<OutletContextProps>();

  const {
    data: payments,
    loading: paymentsLoading,
    refetch,
  } = useGetOrdersQuery({
    variables: {
      input: {
        cursor: {
          first: 10,
        },
      },
    },
  });

  useLayoutEffect(() => {
    if (!payments?.orders?.edges?.length) return;
    setHeaderComponent(() => (
      <div className="h-[80px] max-md:h-[50px] flex items-end">
        <Tabs
          tabs={[
            {
              name: `All (${payments?.orders?.pageInfo?.entries || 0})`,
              link: '/all-payments',
            },
            {
              name: `Successful (${payments?.orders?.pageInfo?.entries || 0})`,
              link: '/all-payments/payments/successful',
            },
            {
              name: 'Pending (0)',
              link: '/all-payments/payments/pending',
            },
            {
              name: 'Failed (0)',
              link: '/all-payments/payments/failed',
            },
          ]}
        />
      </div>
    ));
    return () => {
      setHeaderComponent(undefined);
    };
  }, [
    setHeaderComponent,
    navigate,
    payments?.orders?.pageInfo?.entries,
    payments?.orders?.edges?.length,
  ]);

  useEffect(() => {
    if (!payments?.orders?.edges) return;

    setData(
      payments?.orders?.edges?.map((edge) => ({
        id: edge?.node?.id,
        Name: {
          image: <ActiveIcon />,
          text: `${edge?.node?.firstName || 'Melissa'} ${
            edge?.node?.lastName || 'Mukami'
          }`,
          text2: `${edge?.node?.phoneNumber?.countryCode || ''} ${
            edge?.node?.phoneNumber?.number || ''
          }`,
        },
        'Payment Method': {
          image: getPaymentIcon(edge?.node?.paymentMode),
          text: edge?.node?.paymentMode || '',
        },
        'Payment Date': format(
          new Date(edge?.node?.createdAt as string),
          'PPP - hh:mm a'
        ),
        'Transaction Id': edge?.node?.reference || '',
        amount:
          edge?.node?.cost?.currencyCode +
          ' ' +
          new Intl.NumberFormat('en-US').format(
            Number(edge?.node?.cost?.amountInCents / 1000)
          ),
        status: (
          <div>
            <p className={`${getStatusColor(edge?.node?.state || '')}`}>
              {edge?.node?.state}
            </p>
          </div>
        ),
      }))
    );
  }, [payments]);

  const loadMore = useCallback(
    async (next: boolean) => {
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after = payments?.orders?.pageInfo?.endCursor;
        cursorLoad.first = 10;
      } else {
        cursorLoad.before = payments?.orders?.pageInfo?.startCursor;
        cursorLoad.last = 10;
      }
      const res = await refetch({
        input: {cursor: cursorLoad},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count > 0 ? count - 1 : 0);
      }
    },
    [refetch, payments, count]
  );

  useEffect(() => {
    setCount(1);
  }, [status]);

  return (
    <>
      {paymentsLoading ? (
        <div className="mr-[30px] flex basis-full flex-col h-full justify-center items-center">
          <Spinner />
        </div>
      ) : payments?.orders?.edges?.length ? (
        <div className="mr-[30px] flex basis-full flex-col h-full">
          <div className="flex-grow flex flex-col overflow-auto">
            <div className="flex flex-col border-b border-dividerGrey">
              <h1 className="font-bold text-2xl mt-4">Payments</h1>
              {isDesktop ? (
                <CommonTable
                  columns={[
                    'Name',
                    'Payment Method',
                    'Payment Date',
                    'Transaction Id',
                    'amount',
                    'status',
                  ]}
                  data={data}
                />
              ) : (
                payments?.orders?.edges?.map((item) => (
                  <div
                    key={item?.node?.id}
                    className="flex items-center justify-between border-b-[1px] border-greyish py-[10px]">
                    <div className="flex items-center w-5/6 gap-5">
                      <ProfileIcon />
                      <div className="flex justify-between w-full">
                        <div className="flex-grow">
                          <p className="text-[15px] font-[500]">
                            {item?.node?.firstName || 'Melissa'}{' '}
                            {item?.node?.lastName || 'Mukami'}
                          </p>
                          <p className="text-foggy text-[13px]">
                            {item?.node?.phoneNumber?.countryCode}{' '}
                            {item?.node?.phoneNumber?.number}
                          </p>
                        </div>
                        <p className="text-green-400 font-[500] ml-4">
                          {item?.node?.cost?.currencyCode}{' '}
                          {new Intl.NumberFormat('en-US').format(
                            Number(item?.node?.cost?.amountInCents / 1000)
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <ArrowIcon />
                    </div>
                  </div>
                ))
              )}
            </div>

            {payments?.orders?.pageInfo?.entries &&
              payments.orders.pageInfo.entries > 10 && (
                <div className=" mt-auto py-4">
                  <Paginator
                    count={count}
                    pageInfo={payments?.orders?.pageInfo}
                    loadMore={loadMore}
                  />
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="w-full h-full lg:h-[50%] flex justify-center mt-48">
          <div className="flex text-center flex-col items-center">
            <EmptyIcon />
            <p className="my-5 text-[24px] font-[600]">It's lonely out here</p>
            <p>All your payments will be displayed here</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Payments;
