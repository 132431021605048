import {toast} from '@cashiaApp/web-components';
import {
  useReducer,
  useEffect,
  useContext,
  PropsWithChildren,
  createContext,
} from 'react';

import {isUser} from './apollo/helpers';
import {useAuth} from './auth';
import LoadingModal from '../components/common/LoadingModal';
import {
  useMeQuery,
  UserFieldsFragment,
  BusinessFieldsFragment,
  BusinessOnboardingFieldsFragment,
  useGetBusinessOnboardingQuery,
  useGetBusinessQuery,
} from '../graphql/generated';

export interface State {
  user?: UserFieldsFragment | null;
  business?: BusinessFieldsFragment | null;
  businessOnboarding?: BusinessOnboardingFieldsFragment | null;
  onBoardingRefetch?: () => void;
  businessRefetch?: () => void;
}

export enum ActionKind {
  SET_INIT = 'SET_INIT',
  SET_USER = 'SET_USER',
  SET_BUSINESS_DATA = 'SET_BUSINESS_DATA',
  SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA',
}

type ReducerAction =
  | {type: ActionKind.SET_INIT; payload: State}
  | {type: ActionKind.SET_USER; payload: UserFieldsFragment}
  | {type: ActionKind.SET_BUSINESS_DATA; payload: BusinessFieldsFragment}
  | {
      type: ActionKind.SET_ONBOARDING_DATA;
      payload: BusinessOnboardingFieldsFragment;
    };

export const initialState: State = {};

export function userReducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case ActionKind.SET_INIT:
      return {...state, ...action.payload};
    case ActionKind.SET_USER:
      return {...state, user: action.payload};
    case ActionKind.SET_BUSINESS_DATA:
      return {...state, business: action.payload};
    case ActionKind.SET_ONBOARDING_DATA:
      return {...state, businessOnboarding: action.payload};
    default:
      return state;
  }
}

export const UserContext = createContext<State | undefined>(undefined);

export const useUserAuth = (): State => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserAuth must be used within a UserProvider');
  }
  return context;
};

const UserProvider = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const [state, dispatch] = useReducer(userReducer, initialState);
  const {user, business, businessOnboarding} = state;
  const {
    data: meData,
    error,
    loading: meLoading,
  } = useMeQuery({
    skip: !isAuthenticated,
  });
  const {
    data: onBoardingData,
    loading: onBoardingLoading,
    refetch: onBoardingRefetch,
  } = useGetBusinessOnboardingQuery();
  const {
    data: businessData,
    loading: businessLoading,
    refetch: businessRefetch,
  } = useGetBusinessQuery();

  // Initialize user, business, and onboarding data if available
  useEffect(() => {
    if (isUser(meData?.me) && !error) {
      dispatch({
        type: ActionKind.SET_INIT,
        payload: {
          user: meData?.me,
          business: businessData?.business,
          businessOnboarding: onBoardingData?.businessOnboarding,
        },
      });
    }
  }, [
    error,
    meData?.me,
    businessData?.business,
    onBoardingData?.businessOnboarding,
  ]);

  useEffect(() => {
    if (error && isAuthenticated) {
      toast.error(`Unable to get user info. ${error.message}`);
    }
  }, [error, isAuthenticated]);

  if (meLoading || businessLoading || onBoardingLoading) {
    return <LoadingModal open />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        business,
        businessOnboarding,
        businessRefetch,
        onBoardingRefetch,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
