export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID || '';
export const redirectUrl = process.env.REDIRECT_URL || '';

export const localStorageRefreshTokenKey = 'cashia-token-refresh';
export const localStorageAccessTokenKey = 'accessToken';
export const apiUrl =
  process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql';
export const apiUrlSocket =
  process.env.REACT_APP_API_URL_SOCKET || 'wss://localhost:4000/graphql-ws';
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const colours = {
  greyish: '#D0CECD',
  lightenedGrey: '#EEEEEE',
  foggy: '#757575',
  darkGrey: '#494949',
  sunSetBlue: '#0C0D34',
  white: '#FCFCFC',
  black: '#212121',
  cashiaBlue: '#00A4D4',
  absoluteWhite: '#FFFFFF',
  smoothRed: '#DC1F5C',
  dividerGrey: '#E6E5E5',
  grenish: '#21AA47',
  yellowishWhite: '#d5d5d5',
  darkWhite: '#a2a1a0',
  bgGrey: '#E6E5E5',
  lightGrey: '#F7F7F7',
  iconsGreen: '#06C7CA',
  lightGreen: '#00B097',
  trueBlack: '#000000',
  lazyRed: '#C8171D',
  red: '#FF0000',
  orange: '#FFA500',
  yellow: '#FFFF00',
  green: '#008000',
  blue: '#0000FF',
  purple: '#4B0082',
  avataPurple: '#92ACDF',
  avataGrey: '#CEDAF0',
  lightBlue: '#F3F6F9',
  lightRed: '#ECE4E9',
  modalPurpleBackDropBg: 'rgba(12, 13, 52, 0.8)',
  naviBlue: '#CCF3FF',
  light: '#ededed',
  orangeish: '#FF5733',
  lightPink: '#FCEDEC',
  veryLightBlue: '#E1E7EA',
  lightYellow: '#F29339',
};

export const defaultOperatingSchedule = {
  mo: {from: '09:00', to: '17:00', open: true},
  tu: {from: '09:00', to: '17:00', open: true},
  we: {from: '09:00', to: '17:00', open: true},
  th: {from: '09:00', to: '17:00', open: true},
  fr: {from: '09:00', to: '17:00', open: true},
  sa: {from: '', to: '', open: false},
  su: {from: '', to: '', open: false},
};

export const days = [
  {
    title: 'Monday',
    display: 'Mon',
    key: 'mo',
  },
  {
    title: 'Tuesday',
    display: 'Tue',
    key: 'tu',
  },
  {
    title: 'Wednesday',
    display: 'Wen',
    key: 'we',
  },
  {
    title: 'Thursday',
    display: 'Thu',
    key: 'th',
  },
  {
    title: 'Friday',
    display: 'Fri',
    key: 'fr',
  },
  {
    title: 'Saturday',
    display: 'Sat',
    key: 'sa',
  },
  {
    title: 'Sunday',
    display: 'Sun',
    key: 'su',
  },
];

export const imageRequirementsRules = [
  'All images must be bright, clear and in color.',
  'Pictures must accurately portray the product you are selling.',
  'Pictures added can’t be selfies or people posing for a photo.',
  'Images added should not contain filters, texts, watermarks, or collages.',
  'Do not upload copyrighted images without permission from the owner.',
];

export const newCategoryNameLimit = 30;
