import {ApolloError} from '@apollo/client';
import {Button, Textarea, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as DesktopIconActive} from '../../../assets/icons/desktopPreviewActive.svg';
import {ReactComponent as DesktopIcon} from '../../../assets/icons/desktopPreviewIcon.svg';
import {ReactComponent as MobileIconActive} from '../../../assets/icons/mobilePreviewActive.svg';
import {ReactComponent as MobileIcon} from '../../../assets/icons/mobilePreviewIcon.svg';
import InputErrorMessage from '../../../components/common/InputErrorMessage';
import Spinner from '../../../components/tailwind/Spinner';
import {
  CurrencyCode,
  useAddPaymentLinkMutation,
  useGetBusinessQuery,
  useGetPaymentLinksQuery,
} from '../../../graphql/generated';
import {
  hasSpecialChars,
  isOutOfRange,
  isTooShort,
} from '../../../utils/helper/validation';
import {cn} from '../../../utils/reusableFunctions';
import DesktopPreview from '../Components/DesktopPreview';
import FormInput from '../Components/FormInput';
import Header from '../Components/Header';
import MobilePreview from '../Components/MobilePreview';

interface FormErrors {
  [key: string]: string;
}

export default function CreateLink() {
  const [previewMode, setPreviewMode] = useState<'mobile' | 'desktop'>(
    'mobile'
  );
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [limit, setLimit] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState<FormErrors>({});

  const {refetch} = useGetPaymentLinksQuery({
    variables: {
      input: {
        cursor: {
          first: 10,
        },
      },
    },
  });

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return <InputErrorMessage errorMessage={errors[fieldName]} />;
    }
    return null;
  };

  const handleChange = (
    fieldName: 'title' | 'description' | 'amount' | 'limit',
    newValue: string
  ) => {
    const _errors: FormErrors = {...errors};

    const validators = {
      title: () => {
        setTitle(newValue);
        if (hasSpecialChars(newValue)) {
          return 'Title must not contain special characters.';
        }
        if (isTooShort(newValue, 5)) {
          return 'Title must be at least 5 characters long.';
        }
        return '';
      },
      description: () => {
        setDescription(newValue.trim());
        if (isTooShort(newValue.trim(), 30)) {
          return 'Description must be at least 30 characters long.';
        }
        return '';
      },
      amount: () => {
        setAmount(newValue);
        if (isOutOfRange(newValue, 100, 250000)) {
          return 'Amount must be between 100 and 250,000 KES';
        }
        return '';
      },
      limit: () => {
        setLimit(newValue);
        if (Number(newValue.trim()) > 1000) {
          return 'Payment link limit cannot exceed 1,000';
        }
        if (hasSpecialChars(newValue)) {
          return 'Limit must not contain special characters.';
        }
        return '';
      },
    };

    const error = validators[fieldName]?.() || '';
    if (error) {
      _errors[fieldName] = error;
    } else {
      delete _errors[fieldName];
    }
    setErrors(_errors);
  };

  const [addPaymentLinkMutation, {loading, error}] =
    useAddPaymentLinkMutation();
  const {data, error: getBusinessError} = useGetBusinessQuery();

  useEffect(() => {
    (error?.message || getBusinessError?.message) &&
      toast.error(error?.message || getBusinessError?.message || '');
  }, [error, getBusinessError]);

  const addLink = () => {
    if (Object.keys(errors).length > 0) return;
    addPaymentLinkMutation({
      variables: {
        input: {
          title,
          description,
          cost: {
            amountInCents: Number(amount.replace(/,/g, '')) * 1000,
            currencyCode: CurrencyCode.Kes,
          },
          limit: Number(limit),
        },
      },
    })
      .then((res) => {
        if (res.data?.addPaymentLink) {
          toast.success(
            `Payment link for ${res.data.addPaymentLink.title} created succcessfuly`
          );
          void refetch();
          navigate('/payment-links/link-details', {
            state: {link: res.data.addPaymentLink.id},
          });
        }
      })
      .catch((e: ApolloError) => {
        toast.error(`${e.message}`);
      });
  };

  const btnDisabled = useMemo(
    () => !title || !description || !amount || Object.keys(errors).length > 0,
    [title, description, amount, errors]
  );

  return (
    <div className="w-screen h-screen">
      <Header>Create a payment link</Header>
      <div className="flex w-full h-[95%] max-md:flex-col overflow-y-scroll">
        <p className="px-8 max-md:px-4 font-[600] text-[24px] leading-[20px] lg:hidden mt-5">
          Create a payment link
        </p>
        <div className="w-[40%] max-md:w-full px-8 max-md:px-4 lg:mt-28 max-md:mt-6 h-[90%]">
          <div className="h-full flex flex-col max-md:justify-between">
            <div>
              <div className="max-md:mb-6">
                <FormInput
                  label="Give your product or service a title"
                  name="title"
                  placeholder="e.g. shoes..."
                  onChange={(val) => handleChange('title', val)}
                  maxLength={60}
                  error={renderErrorMessage('title')}
                  required
                />
              </div>
              <div className="mb-4">
                <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
                  Describe your product *
                </p>
                <Textarea
                  rows={2}
                  maxLength={100}
                  minLength={30}
                  className={cn(
                    'min-h-[90px] max-md:w-[92vw] w-[90%] bg-offWhite border-[1px] border-dividerGrey h-auto focus:outline-none focus:ring-0 focus:border-red-300',
                    {
                      'mb-[-10px] border-red-400 border-[1px]':
                        errors['description'],
                    }
                  )}
                  onChange={(e) => handleChange('description', e.target.value)}
                  name="description"
                  placeholder="Give your customer a description of the product/service they are paying for..."
                />
                <div className="relative bottom-6 max-md:left-[60vw] left-[60%] pl-5 w-[120px]">
                  <p className="font-[500] text-[10px] text-foggy">
                    {description?.length || 0} / 100 characters
                  </p>
                </div>
                {renderErrorMessage('description')}
              </div>
              <div className="mb-8">
                <FormInput
                  label="Amount"
                  name="amount"
                  placeholder="0.00"
                  onChange={(value) => {
                    if (/^\d*$/.test(value.replace(/,/g, ''))) {
                      const formattedValue = value
                        ? new Intl.NumberFormat('en-US').format(
                            Number(value.replace(/,/g, ''))
                          )
                        : '';
                      handleChange('amount', formattedValue);
                    }
                  }}
                  required
                  value={amount}
                  leftElement={
                    <div>
                      <p className="font-[600]">KES</p>
                    </div>
                  }
                  error={renderErrorMessage('amount')}
                />
              </div>
              <FormInput
                label="Limit the number of payments"
                name="limit"
                placeholder="0"
                onChange={(val) => handleChange('limit', val)}
                type="number"
                min="0"
                max="1000"
                error={renderErrorMessage('limit')}
              />
            </div>
            <Button
              className={cn(
                'mb-6 lg:mt-10 max-md:w-[92vw] w-[160px] h-[48px] bg-pink',
                {
                  'text-white bg-greyish cursor-default': btnDisabled,
                }
              )}
              disabled={btnDisabled}
              onClick={addLink}>
              {loading ? <Spinner className="fill-white" /> : 'Create link +'}
            </Button>
          </div>
        </div>
        <div className="w-[60%] bg-offWhite px-8 max-md:hidden h-full">
          <div className="flex justify-between w-full pt-5 items-center">
            <p className="font-[700] text-[34px]">Preview</p>
            <div className="flex gap-2 bg-white p-2 rounded-[10px]">
              {previewMode === 'desktop' && (
                <>
                  <MobileIcon
                    className="cursor-pointer"
                    onClick={() => setPreviewMode('mobile')}
                  />
                  <DesktopIconActive
                    className="cursor-pointer"
                    onClick={() => setPreviewMode('desktop')}
                  />
                </>
              )}
              {previewMode === 'mobile' && (
                <>
                  <MobileIconActive
                    className="cursor-pointer"
                    onClick={() => setPreviewMode('mobile')}
                  />
                  <DesktopIcon
                    className="cursor-pointer"
                    onClick={() => setPreviewMode('desktop')}
                  />
                </>
              )}
            </div>
          </div>
          <div className="w-full h-[90%] flex items-center justify-center">
            {previewMode === 'mobile' ? (
              <MobilePreview
                title={title}
                amount={amount}
                name={data?.business?.name}
              />
            ) : (
              <DesktopPreview
                title={title}
                amount={amount}
                name={data?.business?.name}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
