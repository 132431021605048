import {Button, useMediaQuery} from '@cashiaApp/web-components';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import shopIllustration from '../../../assets/images/shopIllustration.svg';
import shopIllustrationMobile from '../../../assets/images/shopIllustrationMobile.png';
import {cn} from '../../../utils/reusableFunctions';

const NoLinks = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const navigate = useNavigate();
  return (
    <div>
      <div className="max-md:mt-[10px] mt-20">
        <p className="font-[700] text-[34px] max-md:text-[24px] max-md:leading-[24px] leading-[34px] mb-2">
          Create your first payment link
        </p>
        <p className="max-md:text[15px]">
          Sell products, offer subscriptions, or accept payments with a link—no
          code required.
        </p>
        <Button
          onClick={() => navigate('/payment-links/create-link')}
          className={cn(
            'w-[266px] h-[52px] rounded-[8px] flex items-center justify-center font-[600] bg-pink text-white my-[20px]'
          )}>
          Create your first payment link +
        </Button>
      </div>
      <div
        className="absolute bottom-5 max-md:w-full max-md:left-0 w-[80%] max-md:h-[480px] h-[60%] bg-cover bg-no-repeat -z-10"
        style={{
          backgroundImage: `url(${
            isDesktop ? shopIllustration : shopIllustrationMobile
          })`,
        }}
      />
    </div>
  );
};

export default NoLinks;
