import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  TimeInHm: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  buildingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
};

export type AddressInput = {
  buildingName?: InputMaybe<Scalars['String']['input']>;
  coordinates: GeoCoordinatesInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  location: Scalars['String']['input'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolder: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bank: Bank;
  id: Scalars['ID']['output'];
};

export type Business = {
  __typename?: 'Business';
  address: Address;
  bankAccount: BankAccount;
  category: BusinessCategory;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Users associated with the business. Only has the user that submitted the form for now */
  members: Array<User>;
  name: Scalars['String']['output'];
  owner: BusinessOwner;
  phoneNumber: PhoneNumber;
  socialMedia: BusinessSocialMedia;
};

export type BusinessCategory = {
  __typename?: 'BusinessCategory';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BusinessOnboarding = {
  __typename?: 'BusinessOnboarding';
  address?: Maybe<Address>;
  bankAccountBank?: Maybe<Bank>;
  bankAccountHolder?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  category?: Maybe<BusinessCategory>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ownerAddress?: Maybe<Address>;
  ownerFirstName?: Maybe<Scalars['String']['output']>;
  ownerIdPhotos?: Maybe<Array<Media>>;
  ownerIdType?: Maybe<VerificationIdType>;
  ownerLastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumber>;
  socialMedia?: Maybe<BusinessSocialMedia>;
  user: User;
};

export type BusinessOnboardingBankAccountInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['ID']['input']>;
};

export type BusinessOnboardingInput = {
  address?: InputMaybe<AddressInput>;
  bankAccount?: InputMaybe<BusinessOnboardingBankAccountInput>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<BusinessOnboardingOwnerInput>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  socialMedia?: InputMaybe<BusinessOnboardingSocialMediaInput>;
};

export type BusinessOnboardingOwnerInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idPhotos?: InputMaybe<Array<Scalars['ID']['input']>>;
  idType?: InputMaybe<VerificationIdType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOnboardingSocialMediaInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  snapchat?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  address: Address;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idPhotos: Array<Media>;
  idType: VerificationIdType;
  lastName: Scalars['String']['output'];
};

export type BusinessSocialMedia = {
  __typename?: 'BusinessSocialMedia';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  snapchat?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Card = {
  __typename?: 'Card';
  flowData?: Maybe<CardFlowData>;
  issuer?: Maybe<CardIssuer>;
  /** card no format eg ********1111 */
  number?: Maybe<Scalars['String']['output']>;
};

export type CardFlowData = {
  __typename?: 'CardFlowData';
  actionUrl?: Maybe<Scalars['String']['output']>;
  referenceId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CardFlowDataInput = {
  actionUrl?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CardInput = {
  /** card cvv format eg 123 */
  cvv?: InputMaybe<Scalars['String']['input']>;
  /** expiryMonth format eg 01 - for January to 12 - for December */
  expiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** expiryYear format eg 2025 */
  expiryYear?: InputMaybe<Scalars['String']['input']>;
  flowData?: InputMaybe<CardFlowDataInput>;
  issuer?: InputMaybe<CardIssuer>;
  /** card no format eg 4111111111111111 */
  number?: InputMaybe<Scalars['String']['input']>;
};

/** CardIssuer to identity the card issuer */
export enum CardIssuer {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA'
}

export enum CurrencyCode {
  /** Kenyan Shilling (KES). */
  Kes = 'KES'
}

export type CursorInput = {
  /** Going forward */
  after?: InputMaybe<Scalars['ID']['input']>;
  /** Going backwards */
  before?: InputMaybe<Scalars['ID']['input']>;
  /** Most be positive integer */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Most be positive integer */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Error = {
  type: ErrorTypes;
};

export enum ErrorTypes {
  AccountLocked = 'ACCOUNT_LOCKED',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  NotRegistered = 'NOT_REGISTERED',
  /** Protected field: Only Admins and owners can access the field */
  ProtectedField = 'PROTECTED_FIELD',
  /** Service error, please retry */
  ServerError = 'SERVER_ERROR',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  ValidationError = 'VALIDATION_ERROR'
}

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type GeoCoordinatesInput = {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']['output']>;
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  coordinates: GeoCoordinates;
  formattedAddress: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LogoutInput = {
  refreshToken: Scalars['String']['input'];
};

export type Media = {
  __typename?: 'Media';
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  amountInCents: Scalars['Int']['output'];
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  amountInCents: Scalars['Int']['input'];
  currencyCode: CurrencyCode;
};

export type Order = {
  __typename?: 'Order';
  card?: Maybe<Card>;
  cost: Money;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  paymentLink: PaymentLink;
  paymentMode: OrderPaymentMode;
  phoneNumber?: Maybe<PhoneNumber>;
  reference: Scalars['String']['output'];
  state: OrderState;
};

export type OrderCursor = {
  __typename?: 'OrderCursor';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
};

export type OrderCursorInput = {
  cursor: CursorInput;
  filter?: InputMaybe<OrderFilter>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['ID']['output'];
  node: Order;
};

export type OrderFilter = {
  paymentLinkId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderInput = {
  card?: InputMaybe<CardInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentLinkId: Scalars['ID']['input'];
  paymentMode: OrderPaymentMode;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

/** OrderPaymentMode to identity method of payment for an order */
export enum OrderPaymentMode {
  Card = 'CARD',
  Mpesa = 'MPESA'
}

/** OrderState to identity the state of an order */
export enum OrderState {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['ID']['output'];
  entries: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  pages: Scalars['Int']['output'];
  startCursor: Scalars['ID']['output'];
};

export type PaymentLink = {
  __typename?: 'PaymentLink';
  active: Scalars['Boolean']['output'];
  business: Business;
  cost: Money;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  /**
   * Unique reference for the payment link to be used as a shorter more user friendly string over id's UUID version
   * this will also be used as the payment link URL identifier
   */
  reference: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PaymentLinkCursor = {
  __typename?: 'PaymentLinkCursor';
  edges: Array<PaymentLinkEdge>;
  pageInfo: PageInfo;
};

export type PaymentLinkCursorInput = {
  cursor: CursorInput;
};

export type PaymentLinkEdge = {
  __typename?: 'PaymentLinkEdge';
  cursor: Scalars['ID']['output'];
  node: PaymentLink;
};

export type PaymentLinkInput = {
  cost: MoneyInput;
  description?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type PhoneNumberInput = {
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Add Order */
  addOrder: Order;
  /** Add PaymentLink */
  addPaymentLink?: Maybe<PaymentLink>;
  /** User login */
  login?: Maybe<Login>;
  /** Logout */
  logout?: Maybe<Scalars['Boolean']['output']>;
  /** Refresh Token */
  refreshToken?: Maybe<Login>;
  /** Resend Email Verification. If no email is provided we will use the auth context to figure it out. */
  resendEmailVerification: Scalars['Boolean']['output'];
  /** User registration */
  signup?: Maybe<User>;
  /** Step up Order to get the next credentials for card iframe */
  stepUpOrder: Order;
  submitBusinessOnboarding: Scalars['Boolean']['output'];
  updateBusinessOnboarding: BusinessOnboarding;
  updatePaymentLink: PaymentLink;
  uploadMedia: Media;
  /** Verify Email with the token user received */
  verifyEmail: Scalars['Boolean']['output'];
};


export type RootMutationTypeAddOrderArgs = {
  input: OrderInput;
};


export type RootMutationTypeAddPaymentLinkArgs = {
  input: PaymentLinkInput;
};


export type RootMutationTypeLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type RootMutationTypeLogoutArgs = {
  input?: InputMaybe<LogoutInput>;
};


export type RootMutationTypeRefreshTokenArgs = {
  input?: InputMaybe<RefreshTokenInput>;
};


export type RootMutationTypeResendEmailVerificationArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeSignupArgs = {
  input?: InputMaybe<SignupInput>;
};


export type RootMutationTypeStepUpOrderArgs = {
  input: OrderInput;
};


export type RootMutationTypeUpdateBusinessOnboardingArgs = {
  input: BusinessOnboardingInput;
};


export type RootMutationTypeUpdatePaymentLinkArgs = {
  input: UpdatePaymentLinkInput;
};


export type RootMutationTypeUploadMediaArgs = {
  file: Scalars['Upload']['input'];
};


export type RootMutationTypeVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  banks: Array<Bank>;
  /** Returns the business associated with the current user, if it exists */
  business?: Maybe<Business>;
  businessCategories: Array<BusinessCategory>;
  businessOnboarding?: Maybe<BusinessOnboarding>;
  /** This is needed to import the error type into the Graphql docs [for Absinthee] */
  error?: Maybe<ValidationError>;
  /** Get current user */
  me?: Maybe<User>;
  /** Get a single order via either id or reference */
  order: Order;
  /** Get all orders belonging to a business with cursor based pagination */
  orders: OrderCursor;
  /** Get a single payment link via either id or reference */
  paymentLink: PaymentLink;
  /** Get all payment links belonging to a business with cursor based pagination */
  paymentLinks: PaymentLinkCursor;
};


export type RootQueryTypeOrderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeOrdersArgs = {
  input: OrderCursorInput;
};


export type RootQueryTypePaymentLinkArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypePaymentLinksArgs = {
  input: PaymentLinkCursorInput;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: PhoneNumberInput;
};

export type UpdatePaymentLinkInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cost?: InputMaybe<MoneyInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  phone?: Maybe<PhoneNumber>;
};

export type ValidationError = Error & {
  __typename?: 'ValidationError';
  key: Scalars['String']['output'];
  /** Should give details sometimes. Example: a @cashia tag is already taken. */
  message?: Maybe<Scalars['String']['output']>;
  type: ErrorTypes;
};

/** Type of identity verification document */
export enum VerificationIdType {
  AlienCard = 'ALIEN_CARD',
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT'
}

export type GeoCoordinatesFieldsFragment = { __typename?: 'GeoCoordinates', lat: number, long: number };

export type LocationFieldsFragment = { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } };

export type AddressFieldsFragment = { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } };

export type BanksFieldsFragment = { __typename?: 'Bank', id: string, name: string };

export type BusinessOwnerFieldsFragment = { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } };

export type BusinessSocialMediaFieldsFragment = { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null };

export type BankAccountFieldsFragment = { __typename?: 'BankAccount', id: string, accountHolder: string, accountNumber: string, bank: { __typename?: 'Bank', id: string, name: string } };

export type BusinessFieldsFragment = { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } };

export type BusinessCategoryFieldsFragment = { __typename?: 'BusinessCategory', id: string, name: string, code: string };

export type BusinessOnboardingFieldsFragment = { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null };

export type ImageFieldsFragment = { __typename?: 'Image', id?: string | null, small?: string | null, medium?: string | null, large?: string | null };

export type MoneyFieldsFragment = { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode };

export type OrderFieldsFragment = { __typename?: 'Order', id: string, reference: string, firstName?: string | null, lastName?: string | null, paymentMode: OrderPaymentMode, state: OrderState, createdAt?: any | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } }, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null };

export type OrderEdgeFieldsFragment = { __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', id: string, reference: string, firstName?: string | null, lastName?: string | null, paymentMode: OrderPaymentMode, state: OrderState, createdAt?: any | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } }, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } };

export type OrderCursorFieldsFragment = { __typename?: 'OrderCursor', edges: Array<{ __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', id: string, reference: string, firstName?: string | null, lastName?: string | null, paymentMode: OrderPaymentMode, state: OrderState, createdAt?: any | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } }, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } };

export type PaymentLinkFieldsFragment = { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } };

export type PaymentLinkEdgeFieldsFragment = { __typename?: 'PaymentLinkEdge', cursor: string, node: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } };

export type PaymentLinkCursorFieldsFragment = { __typename?: 'PaymentLinkCursor', edges: Array<{ __typename?: 'PaymentLinkEdge', cursor: string, node: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } };

export type PhoneNumberFieldsFragment = { __typename?: 'PhoneNumber', number: string, countryCode: string };

export type UserFieldsFragment = { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, emailVerifiedAt?: any | null, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null };

export type AddOrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type AddOrderMutation = { __typename?: 'RootMutationType', addOrder: { __typename?: 'Order', id: string, reference: string, firstName?: string | null, lastName?: string | null, paymentMode: OrderPaymentMode, state: OrderState, createdAt?: any | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } }, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } };

export type AddPaymentLinkMutationVariables = Exact<{
  input: PaymentLinkInput;
}>;


export type AddPaymentLinkMutation = { __typename?: 'RootMutationType', addPaymentLink?: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } | null };

export type ResendEmailVerificationMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResendEmailVerificationMutation = { __typename?: 'RootMutationType', resendEmailVerification: boolean };

export type SubmitBusinessOnboardingMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitBusinessOnboardingMutation = { __typename?: 'RootMutationType', submitBusinessOnboarding: boolean };

export type UpdateBusinessOnboardingMutationVariables = Exact<{
  input: BusinessOnboardingInput;
}>;


export type UpdateBusinessOnboardingMutation = { __typename?: 'RootMutationType', updateBusinessOnboarding: { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null } };

export type UpdatePaymentLinkMutationVariables = Exact<{
  input: UpdatePaymentLinkInput;
}>;


export type UpdatePaymentLinkMutation = { __typename?: 'RootMutationType', updatePaymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } };

export type UploadMediaMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadMediaMutation = { __typename?: 'RootMutationType', uploadMedia: { __typename?: 'Media', id: string } };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'RootMutationType', verifyEmail: boolean };

export type GetBanksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBanksQuery = { __typename?: 'RootQueryType', banks: Array<{ __typename?: 'Bank', id: string, name: string }> };

export type GetBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessQuery = { __typename?: 'RootQueryType', business?: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } | null };

export type GetBusinessCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessCategoriesQuery = { __typename?: 'RootQueryType', businessCategories: Array<{ __typename?: 'BusinessCategory', id: string, name: string, code: string }> };

export type GetBusinessOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessOnboardingQuery = { __typename?: 'RootQueryType', businessOnboarding?: { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null } | null };

export type GetPaymentLinksQueryVariables = Exact<{
  input: PaymentLinkCursorInput;
}>;


export type GetPaymentLinksQuery = { __typename?: 'RootQueryType', paymentLinks: { __typename?: 'PaymentLinkCursor', edges: Array<{ __typename?: 'PaymentLinkEdge', cursor: string, node: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'RootQueryType', me?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, emailVerifiedAt?: any | null, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } | null };

export type GetOrdersQueryVariables = Exact<{
  input: OrderCursorInput;
}>;


export type GetOrdersQuery = { __typename?: 'RootQueryType', orders: { __typename?: 'OrderCursor', edges: Array<{ __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', id: string, reference: string, firstName?: string | null, lastName?: string | null, paymentMode: OrderPaymentMode, state: OrderState, createdAt?: any | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } }, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } } };

export type GetPaymentLinkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaymentLinkQuery = { __typename?: 'RootQueryType', paymentLink: { __typename?: 'PaymentLink', id: string, title: string, description?: string | null, active: boolean, reference: string, createdAt?: any | null, limit?: number | null, cost: { __typename?: 'Money', amountInCents: number, currencyCode: CurrencyCode }, business: { __typename?: 'Business', id: string, name: string, description: string, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } } } };

export const BanksFieldsFragmentDoc = gql`
    fragment banksFields on Bank {
  id
  name
}
    `;
export const BankAccountFieldsFragmentDoc = gql`
    fragment bankAccountFields on BankAccount {
  id
  bank {
    ...banksFields
  }
  accountHolder
  accountNumber
}
    ${BanksFieldsFragmentDoc}`;
export const BusinessCategoryFieldsFragmentDoc = gql`
    fragment businessCategoryFields on BusinessCategory {
  id
  name
  code
}
    `;
export const PhoneNumberFieldsFragmentDoc = gql`
    fragment phoneNumberFields on PhoneNumber {
  number
  countryCode
}
    `;
export const GeoCoordinatesFieldsFragmentDoc = gql`
    fragment geoCoordinatesFields on GeoCoordinates {
  lat
  long
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment locationFields on Location {
  formattedAddress
  coordinates {
    ...geoCoordinatesFields
  }
}
    ${GeoCoordinatesFieldsFragmentDoc}`;
export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on Address {
  id
  location {
    ...locationFields
  }
  buildingName
}
    ${LocationFieldsFragmentDoc}`;
export const BusinessSocialMediaFieldsFragmentDoc = gql`
    fragment businessSocialMediaFields on BusinessSocialMedia {
  website
  facebook
  instagram
  snapchat
}
    `;
export const BusinessOnboardingFieldsFragmentDoc = gql`
    fragment businessOnboardingFields on BusinessOnboarding {
  id
  name
  category {
    ...businessCategoryFields
  }
  description
  phoneNumber {
    ...phoneNumberFields
  }
  address {
    ...addressFields
  }
  ownerFirstName
  ownerLastName
  ownerIdType
  ownerIdPhotos {
    id
    url
    contentType
  }
  ownerAddress {
    ...addressFields
  }
  bankAccountBank {
    id
    name
  }
  bankAccountHolder
  bankAccountNumber
  socialMedia {
    ...businessSocialMediaFields
  }
}
    ${BusinessCategoryFieldsFragmentDoc}
${PhoneNumberFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${BusinessSocialMediaFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = gql`
    fragment imageFields on Image {
  id
  small
  medium
  large
}
    `;
export const MoneyFieldsFragmentDoc = gql`
    fragment moneyFields on Money {
  amountInCents
  currencyCode
}
    `;
export const BusinessOwnerFieldsFragmentDoc = gql`
    fragment businessOwnerFields on BusinessOwner {
  id
  firstName
  lastName
  idType
  idPhotos {
    id
  }
  address {
    ...addressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const BusinessFieldsFragmentDoc = gql`
    fragment businessFields on Business {
  id
  name
  description
  owner {
    ...businessOwnerFields
  }
  phoneNumber {
    ...phoneNumberFields
  }
  category {
    ...businessCategoryFields
  }
  address {
    ...addressFields
  }
  socialMedia {
    ...businessSocialMediaFields
  }
}
    ${BusinessOwnerFieldsFragmentDoc}
${PhoneNumberFieldsFragmentDoc}
${BusinessCategoryFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${BusinessSocialMediaFieldsFragmentDoc}`;
export const PaymentLinkFieldsFragmentDoc = gql`
    fragment paymentLinkFields on PaymentLink {
  id
  title
  description
  cost {
    ...moneyFields
  }
  active
  reference
  active
  createdAt
  limit
  business {
    ...businessFields
  }
}
    ${MoneyFieldsFragmentDoc}
${BusinessFieldsFragmentDoc}`;
export const OrderFieldsFragmentDoc = gql`
    fragment orderFields on Order {
  id
  cost {
    ...moneyFields
  }
  reference
  paymentLink {
    ...paymentLinkFields
  }
  phoneNumber {
    ...phoneNumberFields
  }
  firstName
  lastName
  paymentMode
  state
  createdAt
}
    ${MoneyFieldsFragmentDoc}
${PaymentLinkFieldsFragmentDoc}
${PhoneNumberFieldsFragmentDoc}`;
export const OrderEdgeFieldsFragmentDoc = gql`
    fragment orderEdgeFields on OrderEdge {
  cursor
  node {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export const OrderCursorFieldsFragmentDoc = gql`
    fragment orderCursorFields on OrderCursor {
  edges {
    ...orderEdgeFields
  }
  pageInfo {
    hasNextPage
    hasPrevPage
    startCursor
    endCursor
    entries
    pages
  }
}
    ${OrderEdgeFieldsFragmentDoc}`;
export const PaymentLinkEdgeFieldsFragmentDoc = gql`
    fragment paymentLinkEdgeFields on PaymentLinkEdge {
  cursor
  node {
    ...paymentLinkFields
  }
}
    ${PaymentLinkFieldsFragmentDoc}`;
export const PaymentLinkCursorFieldsFragmentDoc = gql`
    fragment paymentLinkCursorFields on PaymentLinkCursor {
  edges {
    ...paymentLinkEdgeFields
  }
  pageInfo {
    hasNextPage
    hasPrevPage
    startCursor
    endCursor
    entries
    pages
  }
}
    ${PaymentLinkEdgeFieldsFragmentDoc}`;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  firstName
  lastName
  email
  phone {
    ...phoneNumberFields
  }
  createdAt
  emailVerifiedAt
}
    ${PhoneNumberFieldsFragmentDoc}`;
export const AddOrderDocument = gql`
    mutation AddOrder($input: OrderInput!) {
  addOrder(input: $input) {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type AddOrderMutationFn = Apollo.MutationFunction<AddOrderMutation, AddOrderMutationVariables>;

/**
 * __useAddOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderMutation, { data, loading, error }] = useAddOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderMutation, AddOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderMutation, AddOrderMutationVariables>(AddOrderDocument, options);
      }
export type AddOrderMutationHookResult = ReturnType<typeof useAddOrderMutation>;
export type AddOrderMutationResult = Apollo.MutationResult<AddOrderMutation>;
export type AddOrderMutationOptions = Apollo.BaseMutationOptions<AddOrderMutation, AddOrderMutationVariables>;
export const AddPaymentLinkDocument = gql`
    mutation AddPaymentLink($input: PaymentLinkInput!) {
  addPaymentLink(input: $input) {
    ...paymentLinkFields
  }
}
    ${PaymentLinkFieldsFragmentDoc}`;
export type AddPaymentLinkMutationFn = Apollo.MutationFunction<AddPaymentLinkMutation, AddPaymentLinkMutationVariables>;

/**
 * __useAddPaymentLinkMutation__
 *
 * To run a mutation, you first call `useAddPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentLinkMutation, { data, loading, error }] = useAddPaymentLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentLinkMutation, AddPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentLinkMutation, AddPaymentLinkMutationVariables>(AddPaymentLinkDocument, options);
      }
export type AddPaymentLinkMutationHookResult = ReturnType<typeof useAddPaymentLinkMutation>;
export type AddPaymentLinkMutationResult = Apollo.MutationResult<AddPaymentLinkMutation>;
export type AddPaymentLinkMutationOptions = Apollo.BaseMutationOptions<AddPaymentLinkMutation, AddPaymentLinkMutationVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation ResendEmailVerification($email: String) {
  resendEmailVerification(email: $email)
}
    `;
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, options);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = Apollo.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export const SubmitBusinessOnboardingDocument = gql`
    mutation SubmitBusinessOnboarding {
  submitBusinessOnboarding
}
    `;
export type SubmitBusinessOnboardingMutationFn = Apollo.MutationFunction<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>;

/**
 * __useSubmitBusinessOnboardingMutation__
 *
 * To run a mutation, you first call `useSubmitBusinessOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessOnboardingMutation, { data, loading, error }] = useSubmitBusinessOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitBusinessOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>(SubmitBusinessOnboardingDocument, options);
      }
export type SubmitBusinessOnboardingMutationHookResult = ReturnType<typeof useSubmitBusinessOnboardingMutation>;
export type SubmitBusinessOnboardingMutationResult = Apollo.MutationResult<SubmitBusinessOnboardingMutation>;
export type SubmitBusinessOnboardingMutationOptions = Apollo.BaseMutationOptions<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>;
export const UpdateBusinessOnboardingDocument = gql`
    mutation UpdateBusinessOnboarding($input: BusinessOnboardingInput!) {
  updateBusinessOnboarding(input: $input) {
    ...businessOnboardingFields
  }
}
    ${BusinessOnboardingFieldsFragmentDoc}`;
export type UpdateBusinessOnboardingMutationFn = Apollo.MutationFunction<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>;

/**
 * __useUpdateBusinessOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessOnboardingMutation, { data, loading, error }] = useUpdateBusinessOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>(UpdateBusinessOnboardingDocument, options);
      }
export type UpdateBusinessOnboardingMutationHookResult = ReturnType<typeof useUpdateBusinessOnboardingMutation>;
export type UpdateBusinessOnboardingMutationResult = Apollo.MutationResult<UpdateBusinessOnboardingMutation>;
export type UpdateBusinessOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>;
export const UpdatePaymentLinkDocument = gql`
    mutation UpdatePaymentLink($input: UpdatePaymentLinkInput!) {
  updatePaymentLink(input: $input) {
    ...paymentLinkFields
  }
}
    ${PaymentLinkFieldsFragmentDoc}`;
export type UpdatePaymentLinkMutationFn = Apollo.MutationFunction<UpdatePaymentLinkMutation, UpdatePaymentLinkMutationVariables>;

/**
 * __useUpdatePaymentLinkMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentLinkMutation, { data, loading, error }] = useUpdatePaymentLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentLinkMutation, UpdatePaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentLinkMutation, UpdatePaymentLinkMutationVariables>(UpdatePaymentLinkDocument, options);
      }
export type UpdatePaymentLinkMutationHookResult = ReturnType<typeof useUpdatePaymentLinkMutation>;
export type UpdatePaymentLinkMutationResult = Apollo.MutationResult<UpdatePaymentLinkMutation>;
export type UpdatePaymentLinkMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentLinkMutation, UpdatePaymentLinkMutationVariables>;
export const UploadMediaDocument = gql`
    mutation UploadMedia($file: Upload!) {
  uploadMedia(file: $file) {
    id
  }
}
    `;
export type UploadMediaMutationFn = Apollo.MutationFunction<UploadMediaMutation, UploadMediaMutationVariables>;

/**
 * __useUploadMediaMutation__
 *
 * To run a mutation, you first call `useUploadMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMutation, { data, loading, error }] = useUploadMediaMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMediaMutation(baseOptions?: Apollo.MutationHookOptions<UploadMediaMutation, UploadMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, options);
      }
export type UploadMediaMutationHookResult = ReturnType<typeof useUploadMediaMutation>;
export type UploadMediaMutationResult = Apollo.MutationResult<UploadMediaMutation>;
export type UploadMediaMutationOptions = Apollo.BaseMutationOptions<UploadMediaMutation, UploadMediaMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const GetBanksDocument = gql`
    query GetBanks {
  banks {
    ...banksFields
  }
}
    ${BanksFieldsFragmentDoc}`;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBanksQuery(baseOptions?: Apollo.QueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
      }
export function useGetBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export function useGetBanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<typeof useGetBanksLazyQuery>;
export type GetBanksSuspenseQueryHookResult = ReturnType<typeof useGetBanksSuspenseQuery>;
export type GetBanksQueryResult = Apollo.QueryResult<GetBanksQuery, GetBanksQueryVariables>;
export const GetBusinessDocument = gql`
    query GetBusiness {
  business {
    ...businessFields
  }
}
    ${BusinessFieldsFragmentDoc}`;

/**
 * __useGetBusinessQuery__
 *
 * To run a query within a React component, call `useGetBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessQuery, GetBusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessQuery, GetBusinessQueryVariables>(GetBusinessDocument, options);
      }
export function useGetBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessQuery, GetBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessQuery, GetBusinessQueryVariables>(GetBusinessDocument, options);
        }
export function useGetBusinessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBusinessQuery, GetBusinessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBusinessQuery, GetBusinessQueryVariables>(GetBusinessDocument, options);
        }
export type GetBusinessQueryHookResult = ReturnType<typeof useGetBusinessQuery>;
export type GetBusinessLazyQueryHookResult = ReturnType<typeof useGetBusinessLazyQuery>;
export type GetBusinessSuspenseQueryHookResult = ReturnType<typeof useGetBusinessSuspenseQuery>;
export type GetBusinessQueryResult = Apollo.QueryResult<GetBusinessQuery, GetBusinessQueryVariables>;
export const GetBusinessCategoriesDocument = gql`
    query GetBusinessCategories {
  businessCategories {
    ...businessCategoryFields
  }
}
    ${BusinessCategoryFieldsFragmentDoc}`;

/**
 * __useGetBusinessCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBusinessCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
      }
export function useGetBusinessCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
        }
export function useGetBusinessCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
        }
export type GetBusinessCategoriesQueryHookResult = ReturnType<typeof useGetBusinessCategoriesQuery>;
export type GetBusinessCategoriesLazyQueryHookResult = ReturnType<typeof useGetBusinessCategoriesLazyQuery>;
export type GetBusinessCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetBusinessCategoriesSuspenseQuery>;
export type GetBusinessCategoriesQueryResult = Apollo.QueryResult<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>;
export const GetBusinessOnboardingDocument = gql`
    query GetBusinessOnboarding {
  businessOnboarding {
    ...businessOnboardingFields
  }
}
    ${BusinessOnboardingFieldsFragmentDoc}`;

/**
 * __useGetBusinessOnboardingQuery__
 *
 * To run a query within a React component, call `useGetBusinessOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
      }
export function useGetBusinessOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
        }
export function useGetBusinessOnboardingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
        }
export type GetBusinessOnboardingQueryHookResult = ReturnType<typeof useGetBusinessOnboardingQuery>;
export type GetBusinessOnboardingLazyQueryHookResult = ReturnType<typeof useGetBusinessOnboardingLazyQuery>;
export type GetBusinessOnboardingSuspenseQueryHookResult = ReturnType<typeof useGetBusinessOnboardingSuspenseQuery>;
export type GetBusinessOnboardingQueryResult = Apollo.QueryResult<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>;
export const GetPaymentLinksDocument = gql`
    query GetPaymentLinks($input: PaymentLinkCursorInput!) {
  paymentLinks(input: $input) {
    ...paymentLinkCursorFields
  }
}
    ${PaymentLinkCursorFieldsFragmentDoc}`;

/**
 * __useGetPaymentLinksQuery__
 *
 * To run a query within a React component, call `useGetPaymentLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentLinksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPaymentLinksQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentLinksQuery, GetPaymentLinksQueryVariables> & ({ variables: GetPaymentLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>(GetPaymentLinksDocument, options);
      }
export function useGetPaymentLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>(GetPaymentLinksDocument, options);
        }
export function useGetPaymentLinksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>(GetPaymentLinksDocument, options);
        }
export type GetPaymentLinksQueryHookResult = ReturnType<typeof useGetPaymentLinksQuery>;
export type GetPaymentLinksLazyQueryHookResult = ReturnType<typeof useGetPaymentLinksLazyQuery>;
export type GetPaymentLinksSuspenseQueryHookResult = ReturnType<typeof useGetPaymentLinksSuspenseQuery>;
export type GetPaymentLinksQueryResult = Apollo.QueryResult<GetPaymentLinksQuery, GetPaymentLinksQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($input: OrderCursorInput!) {
  orders(input: $input) {
    ...orderCursorFields
  }
}
    ${OrderCursorFieldsFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables> & ({ variables: GetOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export function useGetOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersSuspenseQueryHookResult = ReturnType<typeof useGetOrdersSuspenseQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetPaymentLinkDocument = gql`
    query GetPaymentLink($id: ID, $reference: String) {
  paymentLink(id: $id, reference: $reference) {
    ...paymentLinkFields
  }
}
    ${PaymentLinkFieldsFragmentDoc}`;

/**
 * __useGetPaymentLinkQuery__
 *
 * To run a query within a React component, call `useGetPaymentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useGetPaymentLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>(GetPaymentLinkDocument, options);
      }
export function useGetPaymentLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>(GetPaymentLinkDocument, options);
        }
export function useGetPaymentLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>(GetPaymentLinkDocument, options);
        }
export type GetPaymentLinkQueryHookResult = ReturnType<typeof useGetPaymentLinkQuery>;
export type GetPaymentLinkLazyQueryHookResult = ReturnType<typeof useGetPaymentLinkLazyQuery>;
export type GetPaymentLinkSuspenseQueryHookResult = ReturnType<typeof useGetPaymentLinkSuspenseQuery>;
export type GetPaymentLinkQueryResult = Apollo.QueryResult<GetPaymentLinkQuery, GetPaymentLinkQueryVariables>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  Error: ( ValidationError );
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  Bank: ResolverTypeWrapper<Bank>;
  BankAccount: ResolverTypeWrapper<BankAccount>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Business: ResolverTypeWrapper<Business>;
  BusinessCategory: ResolverTypeWrapper<BusinessCategory>;
  BusinessOnboarding: ResolverTypeWrapper<BusinessOnboarding>;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: ResolverTypeWrapper<BusinessOwner>;
  BusinessSocialMedia: ResolverTypeWrapper<BusinessSocialMedia>;
  Card: ResolverTypeWrapper<Card>;
  CardFlowData: ResolverTypeWrapper<CardFlowData>;
  CardFlowDataInput: CardFlowDataInput;
  CardInput: CardInput;
  CardIssuer: CardIssuer;
  CurrencyCode: CurrencyCode;
  CursorInput: CursorInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>;
  ErrorTypes: ErrorTypes;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Image: ResolverTypeWrapper<Image>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Location: ResolverTypeWrapper<Location>;
  Login: ResolverTypeWrapper<Login>;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: ResolverTypeWrapper<Media>;
  Money: ResolverTypeWrapper<Money>;
  MoneyInput: MoneyInput;
  Order: ResolverTypeWrapper<Order>;
  OrderCursor: ResolverTypeWrapper<OrderCursor>;
  OrderCursorInput: OrderCursorInput;
  OrderEdge: ResolverTypeWrapper<OrderEdge>;
  OrderFilter: OrderFilter;
  OrderInput: OrderInput;
  OrderPaymentMode: OrderPaymentMode;
  OrderState: OrderState;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaymentLink: ResolverTypeWrapper<PaymentLink>;
  PaymentLinkCursor: ResolverTypeWrapper<PaymentLinkCursor>;
  PaymentLinkCursorInput: PaymentLinkCursorInput;
  PaymentLinkEdge: ResolverTypeWrapper<PaymentLinkEdge>;
  PaymentLinkInput: PaymentLinkInput;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RootMutationType: ResolverTypeWrapper<{}>;
  RootQueryType: ResolverTypeWrapper<{}>;
  SignupInput: SignupInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TimeInHm: ResolverTypeWrapper<Scalars['TimeInHm']['output']>;
  UpdatePaymentLinkInput: UpdatePaymentLinkInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  User: ResolverTypeWrapper<User>;
  ValidationError: ResolverTypeWrapper<ValidationError>;
  VerificationIdType: VerificationIdType;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Address: Address;
  AddressInput: AddressInput;
  Bank: Bank;
  BankAccount: BankAccount;
  Boolean: Scalars['Boolean']['output'];
  Business: Business;
  BusinessCategory: BusinessCategory;
  BusinessOnboarding: BusinessOnboarding;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: BusinessOwner;
  BusinessSocialMedia: BusinessSocialMedia;
  Card: Card;
  CardFlowData: CardFlowData;
  CardFlowDataInput: CardFlowDataInput;
  CardInput: CardInput;
  CursorInput: CursorInput;
  DateTime: Scalars['DateTime']['output'];
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error'];
  Float: Scalars['Float']['output'];
  GeoCoordinates: GeoCoordinates;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: Scalars['ID']['output'];
  Image: Image;
  Int: Scalars['Int']['output'];
  Location: Location;
  Login: Login;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: Media;
  Money: Money;
  MoneyInput: MoneyInput;
  Order: Order;
  OrderCursor: OrderCursor;
  OrderCursorInput: OrderCursorInput;
  OrderEdge: OrderEdge;
  OrderFilter: OrderFilter;
  OrderInput: OrderInput;
  PageInfo: PageInfo;
  PaymentLink: PaymentLink;
  PaymentLinkCursor: PaymentLinkCursor;
  PaymentLinkCursorInput: PaymentLinkCursorInput;
  PaymentLinkEdge: PaymentLinkEdge;
  PaymentLinkInput: PaymentLinkInput;
  PhoneNumber: PhoneNumber;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RootMutationType: {};
  RootQueryType: {};
  SignupInput: SignupInput;
  String: Scalars['String']['output'];
  TimeInHm: Scalars['TimeInHm']['output'];
  UpdatePaymentLinkInput: UpdatePaymentLinkInput;
  Upload: Scalars['Upload']['output'];
  User: User;
  ValidationError: ValidationError;
}>;

export type AuthenticatedDirectiveArgs = { };

export type AuthenticatedDirectiveResolver<Result, Parent, ContextType = any, Args = AuthenticatedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ClientDirectiveArgs = { };

export type ClientDirectiveResolver<Result, Parent, ContextType = any, Args = ClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  buildingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']> = ResolversObject<{
  accountHolder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bank?: Resolver<ResolversTypes['Bank'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Business'] = ResolversParentTypes['Business']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  bankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['BusinessCategory'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BusinessOwner'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['PhoneNumber'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['BusinessSocialMedia'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessCategory'] = ResolversParentTypes['BusinessCategory']> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOnboardingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOnboarding'] = ResolversParentTypes['BusinessOnboarding']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  bankAccountBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
  bankAccountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  ownerFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerIdPhotos?: Resolver<Maybe<Array<ResolversTypes['Media']>>, ParentType, ContextType>;
  ownerIdType?: Resolver<Maybe<ResolversTypes['VerificationIdType']>, ParentType, ContextType>;
  ownerLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BusinessSocialMedia']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOwner'] = ResolversParentTypes['BusinessOwner']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idPhotos?: Resolver<Array<ResolversTypes['Media']>, ParentType, ContextType>;
  idType?: Resolver<ResolversTypes['VerificationIdType'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessSocialMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessSocialMedia'] = ResolversParentTypes['BusinessSocialMedia']> = ResolversObject<{
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snapchat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = ResolversObject<{
  flowData?: Resolver<Maybe<ResolversTypes['CardFlowData']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['CardIssuer']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardFlowDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardFlowData'] = ResolversParentTypes['CardFlowData']> = ResolversObject<{
  actionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ValidationError', ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
}>;

export type GeoCoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  small?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = ResolversObject<{
  coordinates?: Resolver<ResolversTypes['GeoCoordinates'], ParentType, ContextType>;
  formattedAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['Login'] = ResolversParentTypes['Login']> = ResolversObject<{
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = ResolversObject<{
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> = ResolversObject<{
  amountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['CurrencyCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = ResolversObject<{
  card?: Resolver<Maybe<ResolversTypes['Card']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType>;
  paymentMode?: Resolver<ResolversTypes['OrderPaymentMode'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['OrderState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCursor'] = ResolversParentTypes['OrderCursor']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['OrderEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderEdge'] = ResolversParentTypes['OrderEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = ResolversObject<{
  endCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPrevPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLink'] = ResolversParentTypes['PaymentLink']> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  business?: Resolver<ResolversTypes['Business'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLinkCursor'] = ResolversParentTypes['PaymentLinkCursor']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['PaymentLinkEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLinkEdge'] = ResolversParentTypes['PaymentLinkEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']> = ResolversObject<{
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RootMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootMutationType'] = ResolversParentTypes['RootMutationType']> = ResolversObject<{
  addOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<RootMutationTypeAddOrderArgs, 'input'>>;
  addPaymentLink?: Resolver<Maybe<ResolversTypes['PaymentLink']>, ParentType, ContextType, RequireFields<RootMutationTypeAddPaymentLinkArgs, 'input'>>;
  login?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeLoginArgs>>;
  logout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<RootMutationTypeLogoutArgs>>;
  refreshToken?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeRefreshTokenArgs>>;
  resendEmailVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<RootMutationTypeResendEmailVerificationArgs>>;
  signup?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<RootMutationTypeSignupArgs>>;
  stepUpOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<RootMutationTypeStepUpOrderArgs, 'input'>>;
  submitBusinessOnboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateBusinessOnboarding?: Resolver<ResolversTypes['BusinessOnboarding'], ParentType, ContextType, RequireFields<RootMutationTypeUpdateBusinessOnboardingArgs, 'input'>>;
  updatePaymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType, RequireFields<RootMutationTypeUpdatePaymentLinkArgs, 'input'>>;
  uploadMedia?: Resolver<ResolversTypes['Media'], ParentType, ContextType, RequireFields<RootMutationTypeUploadMediaArgs, 'file'>>;
  verifyEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<RootMutationTypeVerifyEmailArgs, 'token'>>;
}>;

export type RootQueryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootQueryType'] = ResolversParentTypes['RootQueryType']> = ResolversObject<{
  banks?: Resolver<Array<ResolversTypes['Bank']>, ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  businessCategories?: Resolver<Array<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  businessOnboarding?: Resolver<Maybe<ResolversTypes['BusinessOnboarding']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['ValidationError']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Order'], ParentType, ContextType, Partial<RootQueryTypeOrderArgs>>;
  orders?: Resolver<ResolversTypes['OrderCursor'], ParentType, ContextType, RequireFields<RootQueryTypeOrdersArgs, 'input'>>;
  paymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType, Partial<RootQueryTypePaymentLinkArgs>>;
  paymentLinks?: Resolver<ResolversTypes['PaymentLinkCursor'], ParentType, ContextType, RequireFields<RootQueryTypePaymentLinksArgs, 'input'>>;
}>;

export interface TimeInHmScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TimeInHm'], any> {
  name: 'TimeInHm';
}

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidationError'] = ResolversParentTypes['ValidationError']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Address?: AddressResolvers<ContextType>;
  Bank?: BankResolvers<ContextType>;
  BankAccount?: BankAccountResolvers<ContextType>;
  Business?: BusinessResolvers<ContextType>;
  BusinessCategory?: BusinessCategoryResolvers<ContextType>;
  BusinessOnboarding?: BusinessOnboardingResolvers<ContextType>;
  BusinessOwner?: BusinessOwnerResolvers<ContextType>;
  BusinessSocialMedia?: BusinessSocialMediaResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  CardFlowData?: CardFlowDataResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Error?: ErrorResolvers<ContextType>;
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Login?: LoginResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderCursor?: OrderCursorResolvers<ContextType>;
  OrderEdge?: OrderEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaymentLink?: PaymentLinkResolvers<ContextType>;
  PaymentLinkCursor?: PaymentLinkCursorResolvers<ContextType>;
  PaymentLinkEdge?: PaymentLinkEdgeResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  RootMutationType?: RootMutationTypeResolvers<ContextType>;
  RootQueryType?: RootQueryTypeResolvers<ContextType>;
  TimeInHm?: GraphQLScalarType;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  ValidationError?: ValidationErrorResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  authenticated?: AuthenticatedDirectiveResolver<any, any, ContextType>;
  client?: ClientDirectiveResolver<any, any, ContextType>;
}>;
