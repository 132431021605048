import {clsx, type ClassValue} from 'clsx';
import {twMerge} from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const updateStringStateWithLimit = (
  value: string,
  valueLimit: number,
  setterFunction: (value: string) => void
) => {
  if (value.length >= valueLimit) {
    setterFunction(value.substring(0, valueLimit));
  } else setterFunction(value);
};

export const removeOrAddElementInArray = <T>(array: T[], element: T): T[] => {
  return array.includes(element)
    ? [...array].filter((item) => item !== element)
    : [...array, element];
};

export const convertTo12HourFormat = (time: string): string => {
  const [hours = 0, minutes = 0] = time.split(':').map(Number);
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  const twelveHourFormat = (hours % 12 || 12).toString();
  return `${twelveHourFormat.padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${meridiem}`;
};

export function formatFileSize(fileSizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  let fileSize = fileSizeInBytes;
  while (fileSize >= 1024 && index < units.length - 1) {
    fileSize /= 1024;
    index++;
  }
  return `${fileSize.toFixed(0)} ${units[index] as string}`;
}

export const createHashtag = (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/&/g, 'and')
    .replace(/\s*\/\s*/g, '-')
    .replace(/\/+/g, '-')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
};
