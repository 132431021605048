import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as BackIcon} from '../../../assets/icons/back_arrow_black.svg';

interface HeaderProps {
  children: ReactNode;
}

const Header = ({children}: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className="border-b-[1px] border-greyish h-[88px] max-md:h-[60px] max-md:px-4 px-8 flex items-center gap-4">
      <BackIcon onClick={() => navigate(-1)} className="cursor-pointer" />
      <p className="font-[600] text-[20px] leading-[20px] max-md:hidden">
        {children}
      </p>
    </div>
  );
};

export default Header;
