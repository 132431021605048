import {toast} from '@cashiaApp/web-components';
import axios, {AxiosError, isAxiosError} from 'axios';

const API_BASE_URL = 'https://core-backend.stg.cashia.com';
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface SignUpData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: {
    country_code: string;
    number: string;
  };
}

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: {
    countryCode: string;
    number: string;
  };
  createdAt: string;
  emailVerifiedAt: string | null;
}

export interface SignInData {
  email: string;
  password: string;
}

interface SignUpResponse {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  phone: {
    number: string;
    countryCode: string;
  };
  createdAt: string;
}

interface SignInResponse {
  accessToken: string;
  refreshToken: string;
  user: UserData;
}

interface GraphQLResponse<T> {
  data?: {
    [key: string]: T | undefined;
  };
  errors?: GraphQLError[];
}

export enum SignupErrorCode {
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  INVALID_INPUT = 'INVALID_INPUT',
  PHONE_NUMBER_ALREADY_EXISTS = 'PHONE_NUMBER_ALREADY_EXISTS',
}

export enum LoginErrorCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
}

export type ErrorCode = SignupErrorCode | LoginErrorCode;

export interface GraphQLError {
  message: string;
  extensions?: {
    code?: ErrorCode;
    field?: string;
  };
}

export const signUp = async (userData: SignUpData): Promise<SignUpResponse> => {
  try {
    const response = await api.post<GraphQLResponse<SignUpResponse>>('/query', {
      query: `
        mutation Signup($input: SignupInput!) {
          signup(input: $input) {
            firstName
            lastName
            id
            email
            phone {
              number
              countryCode
            }
            createdAt
          }
        }
      `,
      variables: {
        input: {
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          password: userData.password,
          phone: {
            number: userData.phone.number,
            countryCode: userData.phone.country_code,
          },
        },
      },
    });

    if (response.data.errors && response.data.errors.length > 0) {
      handleSignupErrors(response.data.errors);
    }

    if (!response.data.data?.signup) {
      throw new Error('Signup data is missing from the response');
    }

    return response.data.data.signup;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError<GraphQLResponse<SignUpResponse>>;
      if (axiosError.response?.data?.errors) {
        handleSignupErrors(axiosError.response.data.errors);
      }
    }
    throw new Error('An unexpected error occurred during signup');
  }
};

const handleSignupErrors = (errors: GraphQLError[]) => {
  errors.forEach((error) => {
    const {message, extensions} = error;
    const errorCode = extensions?.code as SignupErrorCode;
    const field = extensions?.field;

    switch (errorCode) {
      case SignupErrorCode.USER_ALREADY_EXISTS:
        toast.error(
          'An account with this email already exists. Please use a different email or try logging in.'
        );
        throw new Error('Email already in use');

      case SignupErrorCode.INVALID_INPUT:
        if (field) {
          let fieldName = field.charAt(0).toUpperCase() + field.slice(1);
          fieldName = fieldName.replace(/([A-Z])/g, ' $1').trim();
          toast.error(`Invalid ${fieldName}: ${message}`);
          throw new Error(`Invalid ${fieldName}: ${message}`);
        } else {
          toast.error(`Invalid input: ${message}`);
          throw new Error(`Invalid input: ${message}`);
        }

      case SignupErrorCode.PHONE_NUMBER_ALREADY_EXISTS:
        toast.error(
          'This phone number is already registered. Please use a different number or try logging in.'
        );
        throw new Error('Phone number already in use');

      default:
        if (
          message.includes('duplicate key value violates unique constraint')
        ) {
          if (message.includes('users_email_key')) {
            toast.error(
              'An account with this email already exists. Please use a different email or try logging in.'
            );
            throw new Error('Email already in use');
          } else if (message.includes('idx_country_code_phone_number')) {
            toast.error(
              'This phone number is already registered. Please use a different number or try logging in.'
            );
            throw new Error('Phone number already in use');
          }
        }
        toast.error(
          message || 'An error occurred during signup. Please try again.'
        );
        throw new Error(message || 'Signup error');
    }
  });
};

export const signIn = async (
  credentials: SignInData
): Promise<SignInResponse> => {
  try {
    const response = await api.post<GraphQLResponse<SignInResponse>>('/query', {
      query: `
      mutation Login($input: LoginInput!) {
        login(input: $input) {
          accessToken
          refreshToken
          user {
            emailVerifiedAt
            createdAt
          }
        }
      }
    `,
      variables: {
        input: credentials,
      },
    });

    if (response.data.errors && response.data.errors.length > 0) {
      handleLoginErrors(response.data.errors);
    }

    if (!response.data.data?.login) {
      throw new Error('Login data is missing from the response');
    }

    return response.data.data.login;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError<GraphQLResponse<SignInResponse>>;
      if (axiosError.response?.data?.errors) {
        handleLoginErrors(axiosError.response.data.errors);
      }
    }
    throw new Error('An unexpected error occurred during login');
  }
};

const handleLoginErrors = (errors: GraphQLError[]) => {
  errors.forEach((error) => {
    const {message, extensions} = error;
    const errorCode = extensions?.code as LoginErrorCode;

    switch (errorCode) {
      case LoginErrorCode.INVALID_CREDENTIALS:
        toast.error(
          'Invalid email or password. Please check your credentials and try again.'
        );
        throw new Error('Invalid credentials');

      case LoginErrorCode.USER_NOT_FOUND:
        toast.error(
          'No account found with this email. Please check your email or sign up for a new account.'
        );
        throw new Error('User not found');

      case LoginErrorCode.ACCOUNT_LOCKED:
        toast.error(
          'Your account has been locked for security reasons. Please contact our support team for assistance.'
        );
        throw new Error('Account locked');

      default:
        toast.error(
          message || 'An error occurred during login. Please try again.'
        );
        throw new Error(message || 'Login error');
    }
  });
};

export const isSignupError = (code: ErrorCode): code is SignupErrorCode => {
  return Object.values(SignupErrorCode).includes(code as SignupErrorCode);
};

export const isLoginError = (code: ErrorCode): code is LoginErrorCode => {
  return Object.values(LoginErrorCode).includes(code as LoginErrorCode);
};
