import {Button} from '@cashiaApp/web-components';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as PendingBubblesIcon} from '../../../assets/icons/pending-bubbles.svg';
import {ReactComponent as ShopAddIcon} from '../../../assets/icons/shop_add.svg';
import {ReactComponent as Tickcon} from '../../../assets/icons/white-tick.svg';
import {
  BusinessFieldsFragment,
  BusinessOnboardingFieldsFragment,
} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';

enum Stage {
  completed = 'completed',
  current = 'current',
  pending = 'pending',
}

type StageProps = {
  num: number;
  info: {label: string; to: string};
  stage: Stage;
};

const stageStyles = (
  type?: Stage
): {
  labelStyles?: string;
  iconStyles?: string;
} => {
  if (!type) return {};
  return {
    completed: {
      iconStyles: 'bg-[#187B33]',
    },
    current: {
      iconStyles: 'border border-smoothRed text-smoothRed',
    },
    pending: {
      labelStyles: 'text-greyish',
      iconStyles: 'border border-greyish text-greyish',
    },
  }[type];
};

const ActivateYourAccount = () => {
  const navigate = useNavigate();
  return (
    <div
      className="p-9 w-full rounded-[10px] text-white"
      style={{
        background:
          'radial-gradient(386.74% 439.6% at 140.65% 449.4%, #FECED8 0%, #DC1F5C 100%)',
      }}>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col gap-[15px] md:mb-0 mb-4">
          <p className="font-bold text-[24px] md:text-[34px]">
            Activate your Account
          </p>
          <p>
            Create a business profile and activate your account to begin using
            Cashia services.
          </p>
        </div>
        <button
          onClick={() => navigate('/home/self-onboarding/business-info')}
          className="font-bold h-12 rounded-lg bg-white text-smoothRed w-full md:w-[286px] flex flex-row items-center justify-center p-4">
          <ShopAddIcon />
          <p className="ml-4">Create your business profile</p>
        </button>
      </div>
    </div>
  );
};

const ActivationStage = ({num, info, stage}: StageProps) => {
  return (
    <div className="flex shrink-0 gap-2.5 items-center">
      <div
        className={cn(
          'flex shrink-0 sm:w-6 lg:w-[30px] sm:h-6 lg:h-[30px] sm:text-[10px] lg:text-sm rounded-full justify-center items-center',
          stageStyles(stage).iconStyles
        )}>
        {stage === Stage.completed ? <Tickcon /> : num}
      </div>
      <p
        className={cn(
          'font-medium shrink-0 sm:text-[15px] lg:text-[16px]',
          stageStyles(stage).labelStyles
        )}>
        {info.label}
      </p>
    </div>
  );
};
const baseRoute = '/home/self-onboarding';
const differentStages = [
  {label: 'Business Information', to: `${baseRoute}/business-info`},
  {label: 'Business Owner Info', to: `${baseRoute}/business-owner-info`},
  {label: 'Bank Account Details', to: `${baseRoute}/bank-info`},
  {label: 'Social Media', to: `${baseRoute}/social-media`},
];

const FinishActivatingYourAccount = ({
  onBoadingProgressNum,
}: {
  onBoadingProgressNum: number;
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <h2 className="sm:font-semibold lg:font-bold sm:text-[20px] lg:text-[34px]">
        Finish activating your account
      </h2>
      <div className="flex w-full overflow-x-scroll sm:flex-col lg:flex-row sm:gap-2 lg:gap-4 sm:mt-5 lg:mt-[30px] sm:mb-5 lg:mb-6 sm:items-start lg:items-center">
        {differentStages.map((item, idx) => (
          <>
            <ActivationStage
              key={idx}
              num={idx + 1}
              stage={
                idx === onBoadingProgressNum
                  ? Stage.current
                  : onBoadingProgressNum > idx
                  ? Stage.completed
                  : Stage.pending
              }
              info={item}
            />
            <div
              key={idx}
              className="last:hidden sm:h-5 lg:h-0.5 sm:w-0.5 lg:w-10 sm:ml-3 lg:ml-0 shrink-0 bg-[#DAD8D8]"
            />
          </>
        ))}
      </div>
      <Button
        size="fit"
        className="bg-smoothRed active:scale-95 w-[155px] h-[48px]"
        onClick={() =>
          navigate(differentStages?.[onBoadingProgressNum]?.to || '')
        }>
        Continue
      </Button>
    </div>
  );
};

const WaitingForReview = () => {
  return (
    <div className="flex sm:flex-col lg:flex-row w-full border items-center rounded-[10px] sm:gap-4 lg:gap-8 sm:py-5 lg:py-8  sm:px-8 lg:px-8">
      <div className="flex flex-col items-center sm:w-full lg:w-fit py-4 px-7 border border-[#FADAAE] rounded-lg gap-3 bg-[#FEF8EE]">
        <PendingBubblesIcon />
        <p className="sm:font-medium lg:font-semibold sm:text-sm lg:text-[16px] bg-[#FADAAE] text-[#E06216] py-2.5 px-2.5 rounded">
          Pending
        </p>
      </div>
      <div className="sm:space-y-3 lg:space-y-[14px]">
        <h3 className="sm:font-semibold lg:font-bold sm:text-2xl lg:text-[34px]">
          Document Status
        </h3>
        <p className="font-normal text-sm text-foggy max-w-[640px]">
          Reviewing your details can take between 1 to 3 business days,
          depending on the completeness and accuracy of the information
          provided. We strive to review and verify your documents as quickly as
          possible.
        </p>
      </div>
    </div>
  );
};

type ActivationProps = {
  business?: BusinessFieldsFragment | null;
  businessOnboarding?: BusinessOnboardingFieldsFragment | null;
};

const getTheCurrentStage = (data: ActivationProps): number => {
  const {businessOnboarding, business} = data;
  if (!businessOnboarding?.name) return 0;
  if (!businessOnboarding.ownerFirstName) return 1;
  if (!businessOnboarding.bankAccountNumber) return 2;
  if (!business) return 3;
  return 4;
};

const Activation = ({business, businessOnboarding}: ActivationProps) => {
  const [onBoadingProgressNum, setOnBoadingProgressNum] = useState(0);

  useEffect(() => {
    if (!businessOnboarding) return;
    setOnBoadingProgressNum(getTheCurrentStage({business, businessOnboarding}));
  }, [business, businessOnboarding]);

  return (
    <div className="flex flex-col gap-5">
      {onBoadingProgressNum === 0 ? (
        <ActivateYourAccount />
      ) : onBoadingProgressNum === 4 ? (
        <WaitingForReview />
      ) : (
        <FinishActivatingYourAccount
          onBoadingProgressNum={onBoadingProgressNum}
        />
      )}
    </div>
  );
};

export default Activation;
