import {Button} from '@cashiaApp/web-components';
import React from 'react';

import {ReactComponent as ShopIcon} from '../../../assets/icons/sidebar/shop.svg';
import mobileFrame from '../../../assets/images/mobileFrame.svg';
import partners from '../../../assets/images/partners.png';
import phoneInput from '../../../assets/images/phoneInputMobile.png';
import statusBar from '../../../assets/images/statusbar.png';
import logo from '../../../assets/logos/cashia_logo_pink.svg';

type Props = {
  title: string;
  amount: string;
  name?: string;
};

export default function MobilePreview({title, amount, name}: Props) {
  return (
    <div>
      <div
        className="w-[314px] h-[630px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${mobileFrame})`,
          backgroundRepeat: 'no-repeat',
        }}>
        <div className="w-[280px] h-[600px] rounded-[50px] bg-white">
          <div>
            <img className="px-5 pt-3" src={statusBar} />
            <div className="flex justify-between border-b-[1px] border-greyish px-4 items-center">
              <div className="flex gap-2 my-3 items-center">
                <ShopIcon width={24} />
                <p className="text-[13px] italic font-[600] text-[13px]">
                  {name || '---'}
                </p>
              </div>
              <img src={logo} width={24} />
            </div>
          </div>
          <div className="px-3">
            <p className="text-md my-3 font-[600]">
              Pay KES {amount || '.....'}
            </p>
            <div className="border-greyish border-[1px] rounded-[5px] p-3">
              <div className="flex justify-between mb-5 overflow-hidden">
                <p className="text-[13px] text-foggy">Item:</p>
                <p className="text-[13px] font-[500] max-w-[200px]">
                  {title || '---'}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[13px] text-foggy">Pay to:</p>
                <p className="text-[13px] font-[500]">{name || '---'}</p>
              </div>
            </div>
            <p className="text-[13px] my-4 font-[600]">
              Choose payment method below
            </p>
            <div>
              <img src={phoneInput} />
              <Button className="h-[40px] w-full bg-greyish mt-3" disabled>
                <p className="text-[12px]">Pay KES {amount || '---'}</p>
              </Button>
              <p className="text-[12px] my-6 w-[220px] text-foggy">
                Cashia is licenced and regulated by the Central Bank of Kenya
              </p>
              <img src={partners} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
