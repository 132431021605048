import {Resolvers} from '@apollo/client';
import {nanoid} from 'nanoid/non-secure';

import {
  CurrencyCode,
  RootMutationTypeResolvers,
  RootQueryTypeResolvers,
  UpdatePaymentLinkInput,
  User,
  OrderPaymentMode,
  OrderState,
  VerificationIdType,
  OrderCursor,
} from '../../../graphql/generated';

export const mockUser: User = {
  __typename: 'User',
  id: nanoid(),
  firstName: 'Melissa',
  lastName: 'Mukami',
  email: 'melissamukami@gmail.com',
  phone: {
    __typename: 'PhoneNumber',
    countryCode: '+254',
    number: '123456789',
  },
  createdAt: new Date().toISOString(),
  logo: {
    __typename: 'Image',
    id: nanoid(),
    small:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    medium:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    large:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
  },
};

const mutationResolvers: RootMutationTypeResolvers = {
  updatePaymentLinkStatus: ({input}: {input: UpdatePaymentLinkInput}) => ({
    __typename: 'PaymentLink',
    id: input.id,
    active: !!input.active,
    business: {
      __typename: 'Business',
      id: nanoid(),
      name: 'GWANDAFISA',
      address: {
        __typename: 'Address',
        buildingName: 'Gallant Mall',
        id: '',
        location: {
          __typename: 'Location',
          coordinates: {
            __typename: 'GeoCoordinates',
            lat: 0,
            long: 0,
          },
          formattedAddress: '123 Gallant Mall, Westlands',
        },
      },
      bankAccount: {
        __typename: 'BankAccount',
        accountHolder: 'John Doe',
        accountNumber: '',
        bank: {
          __typename: 'Bank',
          id: '',
          name: '',
        },
        id: '',
      },
      category: {
        __typename: 'BusinessCategory',
        code: '',
        id: '',
        name: '',
      },
      description: '',
      members: [],
      owner: {
        __typename: 'BusinessOwner',
        address: {
          __typename: 'Address',
          buildingName: 'Gallant Mall',
          id: '',
          location: {
            __typename: 'Location',
            coordinates: {
              __typename: 'GeoCoordinates',
              lat: 0,
              long: 0,
            },
            formattedAddress: '123 Gallant Mall, Westlands',
          },
        },
        firstName: '',
        id: '',
        idPhotos: [],
        idType: VerificationIdType.AlienCard,
        lastName: '',
      },
      phoneNumber: {
        __typename: 'PhoneNumber',
        countryCode: '+254',
        number: '799999999',
      },
      socialMedia: {
        __typename: 'BusinessSocialMedia',
        facebook: '',
        instagram: '',
        snapchat: '',
        website: '',
      },
    },
    cost: {
      __typename: 'Money',
      amountInCents: 1000,
      currencyCode: CurrencyCode.Kes,
    },
    createdAt: new Date().toISOString(),
    description: 'This is a mock payment link description.',
    limit: 100,
    reference: 'mock-ref-1234',
    title: 'Mock Payment Link Title',
  }),
};

export const payments: OrderCursor = {
  __typename: 'OrderCursor',
  edges: [
    {
      __typename: 'OrderEdge',
      cursor: '1',
      node: {
        __typename: 'Order',
        id: nanoid(),
        cost: {
          __typename: 'Money',
          amountInCents: 200000,
          currencyCode: CurrencyCode?.Kes,
        },
        reference: nanoid(),
        paymentLink: {
          __typename: 'PaymentLink',
          id: '0192d7c1-3749-7dd4-b359-f1b4d495eecb',
          title: 'Tablet',
          description: 'Large display, long battery life',
          cost: {
            amountInCents: 1000,
            currencyCode: CurrencyCode?.Kes,
          },
          reference: 'hey',
          business: {
            __typename: 'Business',
            id: nanoid(),
            members: [mockUser],
            name: 'Sample Business',
            description: 'This is a sample business description',
            phoneNumber: {
              __typename: 'PhoneNumber',
              countryCode: '+254',
              number: '987654321',
            },
            address: {
              __typename: 'Address',
              buildingName: 'Building A',
              location: {
                coordinates: {
                  lat: 0,
                  long: 0,
                },
                formattedAddress: '123 Street, City',
              },
              id: nanoid(),
            },
            owner: {
              __typename: 'BusinessOwner',
              id: nanoid(),
              lastName: '',
              firstName: '',
              idPhotos: [],
              idType: VerificationIdType.NationalId,
              address: {
                __typename: 'Address',
                buildingName: 'Building A',
                location: {
                  coordinates: {
                    lat: 0,
                    long: 0,
                  },
                  formattedAddress: '123 Street, City',
                },
                id: nanoid(),
              },
            },
            bankAccount: {
              __typename: 'BankAccount',
              accountHolder: 'John Doe',
              accountNumber: '1234567890',
              bank: {
                __typename: 'Bank',
                name: 'Sample Bank',
                id: nanoid(),
              },
              id: nanoid(),
            },
            category: {
              __typename: 'BusinessCategory',
              id: nanoid(),
              name: 'Sample Category',
              code: '1227H',
            },
            socialMedia: {
              __typename: 'BusinessSocialMedia',
              website: 'https://samplebusiness.com',
            },
          },
          createdAt: new Date().toISOString(),
          limit: 2,
          active: true,
        },
        phoneNumber: {
          __typename: 'PhoneNumber',
          countryCode: '+254',
          number: '987654321',
        },
        firstName: 'John',
        lastName: 'Doe',
        paymentMode: OrderPaymentMode?.Mpesa,
        state: OrderState?.Pending,
        createdAt: new Date().toISOString(),
      },
    },

    {
      __typename: 'OrderEdge',
      cursor: '2',
      node: {
        __typename: 'Order',
        id: nanoid(),
        cost: {
          __typename: 'Money',
          amountInCents: 100000,
          currencyCode: CurrencyCode?.Kes,
        },
        reference: nanoid(),
        paymentLink: {
          __typename: 'PaymentLink',
          id: '0192d7d8-d376-79fe-a17c-0c917d1978a2',
          title: 'hey',
          description: 'This is a sample paymentLink description',
          cost: {
            amountInCents: 1000,
            currencyCode: CurrencyCode?.Kes,
          },
          reference: 'hey',
          business: {
            __typename: 'Business',
            id: nanoid(),
            members: [mockUser],
            name: 'This Business',
            description: 'This is a sample business description',
            phoneNumber: {
              __typename: 'PhoneNumber',
              countryCode: '+254',
              number: '987654321',
            },
            address: {
              __typename: 'Address',
              buildingName: 'Building A',
              location: {
                coordinates: {
                  lat: 0,
                  long: 0,
                },
                formattedAddress: '123 Street, City',
              },
              id: nanoid(),
            },
            owner: {
              __typename: 'BusinessOwner',
              id: nanoid(),
              lastName: '',
              firstName: '',
              idPhotos: [],
              idType: VerificationIdType.NationalId,
              address: {
                __typename: 'Address',
                buildingName: 'Building A',
                location: {
                  coordinates: {
                    lat: 0,
                    long: 0,
                  },
                  formattedAddress: '123 Street, City',
                },
                id: nanoid(),
              },
            },
            bankAccount: {
              __typename: 'BankAccount',
              accountHolder: 'John Doe',
              accountNumber: '1234567890',
              bank: {
                __typename: 'Bank',
                name: 'Sample Bank',
                id: nanoid(),
              },
              id: nanoid(),
            },
            category: {
              __typename: 'BusinessCategory',
              id: nanoid(),
              name: 'Sample Category',
              code: '1227H',
            },
            socialMedia: {
              __typename: 'BusinessSocialMedia',
              website: 'https://samplebusiness.com',
            },
          },
          createdAt: new Date().toISOString(),
          limit: 12,
          active: true,
        },
        phoneNumber: {
          __typename: 'PhoneNumber',
          countryCode: '+254',
          number: '987654321',
        },
        firstName: 'James',
        lastName: 'McDonald',
        paymentMode: OrderPaymentMode?.Mpesa,
        state: OrderState?.Pending,
        createdAt: new Date().toISOString(),
      },
    },
  ],
  pageInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    startCursor: '1',
    endCursor: '2',
    entries: 2,
    pages: 1,
  },
};

const queryResolvers: RootQueryTypeResolvers = {
  me: () => {
    return mockUser;
  },
  orders: () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return payments;
  },
};

const resolvers: Resolvers = {
  Query: {...queryResolvers},
  Mutation: {...mutationResolvers},
};

export default resolvers;
