import {Input, Label} from '@cashiaApp/web-components';

type InputInfo = {
  label: string;
  value?: string | null;
};

const InputInfo = ({label, value}: InputInfo) => {
  return (
    <div className="flex flex-col gap-2 sm:w-full md:w-[520px]">
      <Label
        id={label}
        className="sm:font-medium md:font-semibold sm:text-[15px] md:text-[16px]">
        {label}
      </Label>
      <Input
        className="disabled:cursor-default disabled:opacity-100 sm:text-[15px] md:text-[16px] font-medium"
        value={value || ''}
        id={label}
        disabled
      />
    </div>
  );
};

export default InputInfo;
