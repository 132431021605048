import {buttonVariants, Label} from '@cashiaApp/web-components';

import {ReactComponent as ImagePlaceHolderIcon} from '../../../assets/icons/img-placeholder.svg';
import {VerificationIdType} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import InputInfo from '../components/InputInfo';
import {useBusinessProfileContentContext} from '../layout';

function getFileNameFromUrl(url: string): string {
  const parts = url.split('/');
  return parts[parts.length - 1] || '';
}

const readableVerificationIdType = (type?: VerificationIdType | null) => {
  if (!type) return '';
  return {
    NATIONAL_ID: 'National ID',
    ALIEN_CARD: 'Alien Card',
    PASSPORT: 'Passport No.',
  }[type];
};

const BusinessOwnerInfo = () => {
  const {data} = useBusinessProfileContentContext();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="font-semibold sm:text-[18px] md:text-[24px]">
        Business Owner Information
      </h2>
      <InputInfo
        label="First Name"
        value={data?.businessOnboarding?.ownerFirstName}
      />
      <InputInfo
        label="Last Name"
        value={data?.businessOnboarding?.ownerLastName}
      />
      <div className="flex flex-col gap-3 sm:w-full md:w-[520px]">
        <Label className="sm:font-medium md:font-semibold sm:text-[15px] md:text-[16px]">
          Copy of Valid Proof of Identity
        </Label>
        <div className="flex flex-col gap-3">
          <div className="flex gap-[10px]">
            <ImagePlaceHolderIcon />
            <div className="flex flex-col">
              <span className="text-[12px] font-bold">
                {getFileNameFromUrl(
                  data?.businessOnboarding?.ownerIdPhotos?.[0]?.url || ''
                )}
              </span>
              <span className="text-[#C9C6C5] text-[12px] font-medium">
                PNG, JPG up to 5MB
              </span>
            </div>
          </div>
          <a
            href={data?.businessOnboarding?.ownerIdPhotos?.[0]?.url}
            target="blank"
            className={cn(
              buttonVariants({variant: 'ghost', size: 'fit'}),
              'text-[#C9C6C5] text-[12px] pl-0 font-semibold'
            )}>
            View
          </a>
        </div>
      </div>
      <InputInfo
        label="Identification Document Type"
        value={readableVerificationIdType(
          data?.businessOnboarding?.ownerIdType
        )}
      />
      <InputInfo
        label="Residential Address*"
        value={
          data?.businessOnboarding?.ownerAddress?.location.formattedAddress
        }
      />
      <InputInfo
        label="Complex/ Building Name"
        value={data?.businessOnboarding?.ownerAddress?.buildingName}
      />
    </div>
  );
};

export default BusinessOwnerInfo;
