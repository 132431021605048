import {Button, toast} from '@cashiaApp/web-components';
import {format, parseISO} from 'date-fns';
import Payment from 'payment';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {CardInfo} from '..';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy_red.svg';
import {ReactComponent as ShopIcon} from '../../../assets/icons/sidebar/shop.svg';
import failedPaymentImage from '../../../assets/images/failedPayment.svg';
import mPesaSuccess from '../../../assets/images/mpesa_success.svg';
import pendingImage from '../../../assets/images/pending.svg';
import {ReactComponent as AmericanExpressLogo} from '../../../assets/logos/american_express.svg';
import logo from '../../../assets/logos/cashia_logo_pink.svg';
import {ReactComponent as MastercardLogo} from '../../../assets/logos/mastercard.svg';
import {ReactComponent as MpesaLogo} from '../../../assets/logos/mpesa_small.svg';
import {ReactComponent as VisaLogo} from '../../../assets/logos/visa.svg';
import {Money, Order, useGetPaymentLinkQuery} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';

type Props = {
  method?: string;
};

type StatusProps = {
  status: 'successful' | 'pending' | 'failed';
};

type InfoProps = {
  amount?: Money;
  createdAt?: string;
};

type LocationStateProps = {
  paymentResData: Order;
  cardData: CardInfo;
  status: string;
  phoneNumber?: string;
};

const Banner = ({status, amount, createdAt}: StatusProps & InfoProps) => {
  return (
    <div
      className={cn(
        `inset-x-0 mt-[-75px] w-full h-[30vh] max-md:h-[200px] z-[-1]`,
        {
          'h-[38vh] max-md:h-[320px]': status === 'failed',
        }
      )}
      style={{
        backgroundImage: `url(${
          status === 'successful'
            ? mPesaSuccess
            : status === 'pending'
            ? pendingImage
            : failedPaymentImage
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <div
        className={cn(
          'flex h-full w-screen flex-col justify-end max-md:pb-[30px] pb-[60px] px-40 max-md:px-4',
          {
            'pb-[25px]': status === 'failed',
          }
        )}>
        <p className="text-white font-[500] max-md:text-[13px]">
          {createdAt &&
            format(parseISO(createdAt), 'EEEE, MMM dd, yyyy, hh:mm a')}
        </p>
        <p className="text-white font-[600] text-[34px] max-md:text-[24px]">
          {status === 'successful'
            ? `You paid KES ${
                new Intl.NumberFormat('en-US').format(
                  Number((amount?.amountInCents || 0) / 1000)
                ) || '...'
              }`
            : status === 'pending'
            ? 'Payment pending...'
            : 'Payment failed'}
        </p>
        {status === 'failed' && (
          <div>
            <div className="w-[60px] h-[2px] bg-white my-3 max-md:my-1" />
            <p className="text-white font-[500] my-5 max-md:my-3 text-[15px]">
              Reason for the failure of this payment will be indicated here.
            </p>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText('support@cashia.com');
                toast.success('Support email copied!');
              }}
              className="h-[42px] w-[220px] bg-white text-darkRed flex gap-2 items-center z-[100]">
              support@cashia.com
              <CopyIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Row = ({label, value}: {label: string; value?: string | ReactNode}) => {
  return (
    <div className="flex w-full justify-between border-b-[1px] border-greyish pt-6 py-3">
      <p className="font-[500] text-foggy">{label}:</p>
      <div className="font-[600]">{value || ''}</div>
    </div>
  );
};

const Status = ({status}: StatusProps) => {
  const textColor =
    status === 'successful'
      ? 'text-brightGreen'
      : status === 'pending'
      ? 'text-[#F29339]'
      : 'text-[#E62229]';
  return (
    <p className={`${textColor}`}>
      {String(status).charAt(0).toUpperCase() + String(status).slice(1)}
    </p>
  );
};

export default function PaymentStatus({method = 'mpesa'}: Props) {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const status = (location.state as StatusProps)?.status;
  const phoneNumber = (location.state as LocationStateProps)?.phoneNumber;
  const cardData = (location.state as LocationStateProps)?.cardData;
  const paymentResData = (location.state as LocationStateProps)?.paymentResData;
  const {reference} = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const {data} = useGetPaymentLinkQuery({
    variables: {
      reference,
    },
    skip: !reference,
  });

  const transactionDetails = useMemo(
    () => ({
      recipient: {
        name: `${paymentResData.firstName || ''} ${
          paymentResData.lastName || ''
        }`,
        phoneNumber: `${paymentResData.phoneNumber?.countryCode || ''} ${
          paymentResData.phoneNumber?.number || ''
        }`,
      },
      status: <Status status={status} />,
      method: {
        type: method === 'card' ? 'Card' : 'M-PESA',
        logo: method !== 'card' && <MpesaLogo />,
      },
      transactionId: paymentResData.reference,
      sender: {
        name: `${paymentResData.firstName || 'Melissa'} ${
          paymentResData.lastName || 'Mukami'
        }`,
      },
    }),
    [status, method, paymentResData]
  );

  return (
    <div className="min-h-screen flex flex-col justify-between flex-grow">
      <div>
        <div
          className={`h-[65px] max-md:px-4 sticky top-0 z-10 flex justify-between px-8 items-center transition duration-300 ${
            scrolled ? 'bg-white opacity-100' : 'bg-white/30 opacity-70'
          } border-b-[1px] border-greyish`}>
          <div className="flex gap-2 my-3 items-center">
            <ShopIcon width={24} />
            <p className="italic font-[600]">
              {data?.paymentLink?.business?.name || '---'}
            </p>
          </div>
          <img src={logo} width={30} />
        </div>
        <Banner
          status={status || 'successful'}
          amount={data?.paymentLink?.cost}
          createdAt={paymentResData?.createdAt as string}
        />
        <div
          className={cn('px-40 max-md:px-4', {
            'max-md:top-[35%]': status === 'failed',
          })}>
          <Row label="To" value={data?.paymentLink?.business?.name} />
          <Row label="Status" value={transactionDetails.status} />
          <Row
            label="Method"
            value={
              <div className="flex gap-3 items-center">
                {transactionDetails.method.logo}
                <p className="font-[600]">{method.toUpperCase()}</p>
              </div>
            }
          />
          <Row
            label="Transaction ID"
            value={transactionDetails.transactionId}
          />
          <Row label="From" value={transactionDetails.sender.name} />
          <Row
            label="Phone number"
            value={`+254 ${phoneNumber || cardData.phoneNumber || ''}`}
          />
          {method === 'card' && (
            <Row
              label="Card number"
              value={
                <div className="flex items-center gap-1">
                  <div>
                    {Payment.fns.cardType(cardData.number || '') === 'visa' && (
                      <VisaLogo className="min-w-[24px] h-[28px]" />
                    )}
                    {Payment.fns.cardType(cardData.number || '') ===
                      'mastercard' && (
                      <MastercardLogo className="min-w-[24px] h-[28px]" />
                    )}
                    {Payment.fns.cardType(cardData.number || '') === 'amex' && (
                      <AmericanExpressLogo className="min-w-[34px] h-[28px]" />
                    )}
                  </div>
                  <p>**** {cardData?.number?.slice(-4)}</p>
                </div>
              }
            />
          )}
        </div>
      </div>
      <div className="font-[500] text-foggy flex justify-between px-10 pb-4 max-md:hidden">
        <p>
          Powered by <span className="font-[700] text-smoothRed">Cashia</span>
        </p>
        <div className="flex gap-4">
          <p className="border-r-[1px] border-greyish pr-6">
            Terms & conditions
          </p>
          <p>Privacy policy</p>
        </div>
      </div>
    </div>
  );
}
