import {Button, useMediaQuery} from '@cashiaApp/web-components';
import {useNavigate} from 'react-router-dom';

import Activation from './components/activation';
import {ReactComponent as DownArrowIcon} from '../../assets/icons/down-arrow.svg';
import {ReactComponent as Pluscon} from '../../assets/icons/plus.svg';
import {cn} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';

const Home = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const {business, businessOnboarding} = useUserAuth();

  return (
    <div className="w-full h-full overflow-y-scroll sm:py-5 lg:py-8 pb-10">
      {(business?.owner.firstName || businessOnboarding?.ownerFirstName) && (
        <h2 className="sm:flex lg:hidden mb-7 capitalize font-semibold text-2xl">
          Hi {business?.owner.firstName || businessOnboarding?.ownerFirstName}
        </h2>
      )}
      <div className="flex w-full flex-col gap-5">
        <Activation
          business={business}
          businessOnboarding={businessOnboarding}
        />
        <div className="w-full h-[1px] bg-[#DAD8D8]" />
        <div className="flex flex-col gap-4">
          <div className="sm:hidden lg:flex items-center gap-[6px]">
            <h3 className="font-semibold text-[20px]">Today</h3>
            <DownArrowIcon />
          </div>
          <div className="flex flex-col w-full gap-9 pb-8 border rounded-xl">
            <div className="flex w-full justify-between items-start px-5 pt-5">
              <div className="flex flex-col">
                <p className="font-medium text-[15px] text-foggy">Revenue</p>
                <p className="font-bold text-[24px]">KES 0.00</p>
              </div>
              <div className="sm:flex lg:hidden items-center gap-1">
                <h3 className="font-medium text-[15px]">Today</h3>
                <DownArrowIcon />
              </div>
            </div>
            <div className="sm:hidden lg:flex w-full flex-col gap-8">
              {Array.from({length: 5}, (_, idx) => (
                <div
                  key={idx}
                  className="w-full h-[1px] border-dashed border border-[#E1E3E8]"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#DAD8D8]" />
        <div className="sm:space-y-[10px] lg:space-y-7">
          <div className="sm:space-y-1 lg:space-y-0">
            <h3 className="sm:font-semibold lg:font-bold sm:text-[20px] lg:text-[34px]">
              Create your first payment link
            </h3>
            <p className="font-medium sm:text-[15px] lg:text-[16px]">
              Sell products, offer subscriptions, or accept payments with a
              link—no code required.
            </p>
          </div>
          <Button
            variant={isDesktop ? undefined : 'outline'}
            className={cn(
              'flex active:scale-95 sm:justify-start lg:justify-center gap-2 border-smoothRed hover:border-smoothRed sm:w-full lg:w-[255px] sm:h-[66px] lg:h-[48px] sm:py-0 lg:py-4 sm:px-4 lg:px-0 font-medium text-[15px]',
              {'bg-smoothRed': isDesktop}
            )}
            onClick={() => navigate('/payment-links/create-link')}>
            <div
              className={cn(
                'sm:flex lg:hidden w-9 h-9 shrink-0 rounded-full justify-center items-center bg-smoothRed'
              )}>
              <Pluscon className="w-4 h-4" />
            </div>
            <span>Create your first payment link {isDesktop && `+`}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
