import {
  Avatar,
  Sheet,
  Button,
  SheetContent,
  useMediaQuery,
} from '@cashiaApp/web-components';
import {useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as BurgerIcon} from '../assets/icons/burger.svg';
import {ReactComponent as LogoutIcon} from '../assets/icons/logout.svg';
import ToggleSvg from '../assets/icons/sidebar/Toggle.svg';
import {ReactComponent as BusinessProfileActive} from '../assets/icons/sidebar/business-profile-active.svg';
import {ReactComponent as BusinessProfileInactive} from '../assets/icons/sidebar/business-profile-inactive.svg';
import {ReactComponent as ChevronUpIcon} from '../assets/icons/sidebar/chevron-up.svg';
import {ReactComponent as ChevronDownIcon} from '../assets/icons/sidebar/chevron_down.svg';
import {ReactComponent as HomeActive} from '../assets/icons/sidebar/home_active.svg';
import {ReactComponent as HomeInactive} from '../assets/icons/sidebar/home_inactive.svg';
import {ReactComponent as PaymentLinkActive} from '../assets/icons/sidebar/payment-link-active.svg';
import {ReactComponent as PaymentLinkInActive} from '../assets/icons/sidebar/payment-link-inactive.svg';
import {ReactComponent as PaymentsActive} from '../assets/icons/sidebar/payments-active.svg';
import {ReactComponent as PaymentsInactive} from '../assets/icons/sidebar/payments-inactive.svg';
import {ReactComponent as PayoutsActive} from '../assets/icons/sidebar/payouts-active.svg';
import {ReactComponent as PayoutsInactive} from '../assets/icons/sidebar/payouts-inactive.svg';
import {ReactComponent as SettingsActive} from '../assets/icons/sidebar/settings-active.svg';
import {ReactComponent as SettingsInactive} from '../assets/icons/sidebar/settings-inactive.svg';
import {ReactComponent as ShopIcon} from '../assets/icons/sidebar/shop.svg';
import LogoSvg from '../assets/logos/cashia_logo_pink.svg';
import {BusinessFieldsFragment} from '../graphql/generated';
import {useAuth} from '../utils/auth';
import {mockUser} from '../utils/mocks/resolvers';
import {cn} from '../utils/reusableFunctions';
import {useUserAuth} from '../utils/user';

type MenuItem = {
  label: string;
  path: string;
  activeIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {title?: string}
  >;
  inactiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {title?: string}
  >;
};

// Centralized icon map
const Icons = {
  Home: {active: HomeActive, inactive: HomeInactive},
  PaymentLinks: {active: PaymentLinkActive, inactive: PaymentLinkInActive},
  Payments: {active: PaymentsActive, inactive: PaymentsInactive},
  Payouts: {active: PayoutsActive, inactive: PayoutsInactive},
  Settings: {active: SettingsActive, inactive: SettingsInactive},
  BusinessProfile: {
    active: BusinessProfileActive,
    inactive: BusinessProfileInactive,
  },
};

// Utility function to create a menu item
const createMenuItem = (
  label: string,
  path: string,
  iconKey: keyof typeof Icons
): MenuItem => ({
  label,
  path,
  activeIcon: Icons[iconKey].active,
  inactiveIcon: Icons[iconKey].inactive,
});

// Primary menu items
const primaryMenuItems: MenuItem[] = [
  createMenuItem('Home', '/home', 'Home'),
  createMenuItem('Payment links', '/payment-links', 'PaymentLinks'),
];

// Transactions menu items
const transactionsMenuItems: MenuItem[] = [
  createMenuItem('Payments', '/all-payments', 'Payments'),
  createMenuItem('Payouts', '/payouts', 'Payouts'),
];

// Secondary menu items with conditional logic for "Business Profile"
const secondaryMenuItems = (
  business?: BusinessFieldsFragment | null
): MenuItem[] => {
  const items = [createMenuItem('Settings', '/settings', 'Settings')];

  return business
    ? [
        createMenuItem(
          'Business Profile',
          '/business-profile',
          'BusinessProfile'
        ),
        ...items,
      ]
    : items;
};

const SidebarContent = ({closeSidebar}: {closeSidebar: () => void}) => {
  const {business, businessOnboarding} = useUserAuth();
  const {logout} = useAuth();
  return (
    <div className="flex flex-col justify-between h-full overflow-y-scroll">
      <div className="flex-flex-col w-full">
        <div className="flex flex-col px-4 gap-10 border-b border-Greyish pb-4">
          <div className="flex gap-3">
            <ShopIcon />
            <p className="font-semibold capitalize text-[20px] truncate">
              {`${
                business?.owner.firstName ||
                businessOnboarding?.ownerFirstName ||
                ''
              } ${
                business?.owner?.lastName ||
                businessOnboarding?.ownerLastName ||
                ''
              }`}
            </p>
          </div>
          <div className="flex flex-col py-[10px] px-4 border border-[#DAD8D8] rounded-lg">
            <span className="text-foggy text-sm font-medium">
              Business Name
            </span>
            <p className="text-[16px] font-medium truncate">
              {business?.name || businessOnboarding?.name}
              {' - '}
              {business?.address?.location.formattedAddress ||
                businessOnboarding?.address?.location.formattedAddress}
            </p>
          </div>
          <div className="flex flex-col gap-1 w-full">
            {primaryMenuItems.map((menu) => (
              <MenuItem
                key={menu.label}
                menuItem={menu}
                closeSidebar={closeSidebar}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3 px-4 pt-5 pb-4 w-full border-b border-Greyish">
          <div className="flex justify-between text-foggy pl-4 items-center">
            <p className="text-sm font-semibold">Transactions</p>
            <ChevronUpIcon />
          </div>
          <div className="flex flex-col gap-1 w-full">
            {transactionsMenuItems.map((menu) => (
              <MenuItem
                key={menu.label}
                menuItem={menu}
                closeSidebar={closeSidebar}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1 w-full px-4 pt-4">
          {secondaryMenuItems(business).map((menu) => (
            <MenuItem
              key={menu.label}
              menuItem={menu}
              closeSidebar={closeSidebar}
            />
          ))}
        </div>
      </div>
      <div className="w-full px-4 mb-6">
        <div
          onClick={logout}
          className="flex items-center rounded-[10px] hover:bg-[#FBE4EC]/70 px-4 gap-4 w-full h-12 cursor-pointer">
          <LogoutIcon />
          <p className="text-[16px] text-[#EA3A3D] font-medium">Logout</p>
        </div>
      </div>
    </div>
  );
};

const MenuItem = ({
  menuItem,
  closeSidebar,
}: {
  menuItem: MenuItem;
  closeSidebar: () => void;
}) => {
  const location = useLocation();
  const active = location.pathname.includes(menuItem.path);
  const nav = useNavigate();

  const handleMenuClick = () => {
    nav(menuItem.path);
    closeSidebar();
  };

  return (
    <div
      className={cn(
        'flex items-center rounded-[10px] hover:bg-gray-300/50 px-4 gap-4 w-full h-12 cursor-pointer',
        {
          'bg-[#FBE4EC] hover:bg-[#FBE4EC]': active,
        }
      )}
      onClick={handleMenuClick}>
      {active ? <menuItem.activeIcon /> : <menuItem.inactiveIcon />}
      <p
        className={cn('text-[16px] text-[#212121] font-medium', {
          'text-smoothRed font-semibold': active,
        })}>
        {menuItem.label}
      </p>
    </div>
  );
};

export type OutletContextProps = {
  setHeaderComponent: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  headerComponent: JSX.Element | undefined;
};

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [headerComponent, setHeaderComponent] = useState<JSX.Element>();
  return (
    <main className="flex w-screen h-screen overflow-hidden">
      <aside className="w-[280px] h-full border-r border-Greyish sm:hidden lg:flex flex-col justify-between pt-7 pb-9 shrink-0 bg-offWhite overflow-hidden">
        <SidebarContent closeSidebar={() => setIsSidebarOpen(false)} />
      </aside>
      <div className="flex flex-col h-full sm:w-full lg:w-[calc(100svw-280px)] overflow-hidden">
        <nav
          className={cn(
            'h-[80px] sm:px-4 md:px-8 flex items-center border-b-2',
            {'max-md:h-[100px]': headerComponent}
          )}>
          <div className="flex flex-col w-full">
            <div className="flex w-full sm:justify-between lg:justify-between items-center max-md:mt-[10px]">
              <Button
                variant="ghost"
                className="sm:flex lg:hidden px-0"
                onClick={() => setIsSidebarOpen(true)}>
                <BurgerIcon />
              </Button>
              {isDesktop && (
                <div className="w-full lg:pl-6">{headerComponent}</div>
              )}
              <Sheet
                open={isSidebarOpen}
                onOpenChange={() => setIsSidebarOpen(false)}>
                <SheetContent
                  side="left"
                  className="p-0 pt-10 h-full sm:w-[90%] md:w-[350px]">
                  <SidebarContent
                    closeSidebar={() => setIsSidebarOpen(false)}
                  />
                </SheetContent>
              </Sheet>
              <div className="max-md:hidden flex gap-1 border py-1 border-Greyish w-20 h-11 rounded-[10px] justify-center items-center">
                <ChevronDownIcon />
                <Avatar
                  shape="rounded"
                  className="bg-cashiaBlue/70 h-8 w-8 text-white"
                  src={mockUser?.logo?.small || ''}
                />
              </div>
              <div className="md:hidden flex lg:hidden gap-3 h-11 items-center">
                <img src={ToggleSvg} className="w-7 h-7 lg:mx-0" alt="Logo" />
                <img src={LogoSvg} className="w-6 h-6 lg:mx-0" alt="Logo" />
              </div>
            </div>
            {!isDesktop && (
              <div className="w-full md:pr-8">{headerComponent}</div>
            )}
          </div>
        </nav>
        <div className="md:pl-8 lg:pl-12 md:pr-8 sm:px-4 w-full h-[calc(100svh-80px)] overflow-hidden">
          <Outlet
            context={{
              headerComponent,
              setHeaderComponent,
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default MainLayout;
