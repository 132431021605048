import {parse, isFuture, isValid, isThisMonth} from 'date-fns';
import Payment from 'payment';

function clearNumber(value = ''): string {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value: string): string {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue: string;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: string): string {
  const clearValue = clearNumber(value);
  const maxLength = 3;

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string): string {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function isValidExpirationDate(value: string): boolean {
  const clearValue = clearNumber(value);

  if (clearValue.length !== 4) {
    return false; // Expiration date must be exactly 4 digits (MMYY)
  }

  const month = parseInt(clearValue.slice(0, 2), 10);
  const year = parseInt(clearValue.slice(2, 4), 10) + 2000; // Convert YY to YYYY

  // Month must be between 1 and 12
  if (month < 1 || month > 12) {
    return false;
  }

  // Create a date object for the last day of the expiration month
  const expirationDate = parse(`${year}-${month}-01`, 'yyyy-MM-dd', new Date());
  return (
    isValid(expirationDate) &&
    (isFuture(expirationDate) || isThisMonth(expirationDate))
  );
}
