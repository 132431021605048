import {ApolloError} from '@apollo/client';
import {createContext, useContext, useLayoutEffect} from 'react';
import {Outlet, useOutletContext} from 'react-router-dom';

import {
  GetBusinessOnboardingQuery,
  useGetBusinessOnboardingQuery,
} from '../../../graphql/generated';
import {OutletContextProps} from '../../../layouts/MainLayout';
import Tabs from '../components/Tabs';

const tabs = [
  {
    name: 'Business Information',
    link: '/business-profile',
  },
  {
    name: 'Business Owner Info',
    link: '/business-profile/owner-info',
  },
  {
    name: 'Bank Account Details',
    link: '/business-profile/bank-details',
  },
  {
    name: 'Social Media',
    link: '/business-profile/social-media',
  },
];

type SelfOnboardingContent = {
  data?: GetBusinessOnboardingQuery;
  loading: boolean;
  error?: ApolloError;
};

const BusinessProfileContentContext = createContext<SelfOnboardingContent>({
  loading: false,
});

export const useBusinessProfileContentContext = () =>
  useContext(BusinessProfileContentContext);

const BusinessProfileLayout = () => {
  const {data, loading, error} = useGetBusinessOnboardingQuery();
  const {setHeaderComponent} = useOutletContext<OutletContextProps>();

  useLayoutEffect(() => {
    setHeaderComponent(() => (
      <div className="h-[80px] max-md:h-[50px] flex items-end">
        <Tabs tabs={tabs} />
      </div>
    ));
    return () => {
      setHeaderComponent(undefined);
    };
  }, [setHeaderComponent]);

  return (
    <>
      <div className="flex flex-col py-8 pb-36 sm:gap-5 md:gap-8 h-full w-full overflow-y-scroll">
        <h1 className="sm:text-[24px] md:text-4xl font-semibold">
          Business Profile
        </h1>
        <div className="flex-1 scroll-smooth">
          <BusinessProfileContentContext.Provider
            value={{
              data,
              loading,
              error,
            }}>
            <Outlet />
          </BusinessProfileContentContext.Provider>
        </div>
      </div>
    </>
  );
};

export default BusinessProfileLayout;
