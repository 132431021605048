import {Input} from '@cashiaApp/web-components';
import React, {useMemo, useEffect} from 'react';
import Select, {StylesConfig} from 'react-select';

interface CountryOption {
  label: string;
  value: string;
  flag: string;
}

interface CustomPhoneInputProps {
  countryCode: string;
  phoneNumber: string;
  onChange: (countryCode: string, number: string) => void;
  error?: string;
  isDisabled?: boolean;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  countryCode,
  phoneNumber,
  onChange,
  error,
  isDisabled = false,
}) => {
  const options = useMemo<CountryOption[]>(
    () => [
      {
        label: '+254',
        value: 'KE',
        flag: 'https://flagcdn.com/24x18/ke.png',
      },
    ],
    []
  );

  const selectedCountry =
    options.find((option) => option.label === countryCode) || options[0];

  const handleCountryChange = (option: CountryOption | null) => {
    if (option) {
      onChange(option.label, phoneNumber || '');
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.replace(/\D/g, '');
    onChange(`${countryCode ?? options[0]?.label ?? ''}`, numberValue);
  };

  useEffect(() => {
    if (!countryCode) {
      onChange(`${options[0]?.label ?? ''} `, '');
    }
  }, [countryCode, onChange, options]);

  const customStyles: StylesConfig<CountryOption, false> = {
    control: (provided, state) => ({
      ...provided,
      width: '100px',
      height: '46px',
      borderRadius: '8px',
      backgroundColor: isDisabled ? 'transparent' : undefined,
      borderColor: '#E6E5E5',
      boxShadow: state.isFocused ? '0 0 0 1px #FECED8' : 'none',
      '&:hover': {
        borderColor: '#FECED8',
      },
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const formatOptionLabel = ({label, flag}: CountryOption) => (
    <div className="flex items-center">
      <img src={flag} alt="flag" className="mr-2" />
      <p className="text-[14px]">{label}</p>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between gap-2">
        <Select<CountryOption>
          options={options}
          value={selectedCountry}
          onChange={handleCountryChange}
          isDisabled={isDisabled}
          styles={customStyles}
          formatOptionLabel={formatOptionLabel}
          placeholder="Select country"
        />
        <Input
          type="tel"
          name="phoneNumber"
          value={phoneNumber || ''}
          onChange={handlePhoneNumberChange}
          className="bg-transparent rounded-lg h-[46px] max-md:w-[60vw] w-[380px] focus-visible:border-none focus-visible:ring-[#FECED8]"
          placeholder=""
          disabled={isDisabled}
        />
      </div>
      {error && <p className="text-red-500 mt-1">{error}</p>}
    </div>
  );
};

export default CustomPhoneInput;
