import React from 'react';

import {ReactComponent as Info} from '../../assets/icons/infoRed.svg';

export default function InputErrorMessage({
  errorMessage,
}: {
  errorMessage?: string;
}) {
  return errorMessage ? (
    <div className="flex items-center h-[10px] gap-1 mt-2 max-md:mt-4">
      <Info className="w-[15px]" />
      <span className="text-orangeDark px-1 rounded text-[12px] font-normal">
        {errorMessage}
      </span>
    </div>
  ) : null;
}
