import {
  Observable,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import axios, {AxiosInstance} from 'axios';
import {createClient} from 'graphql-ws';
import {jwtDecode} from 'jwt-decode';
import React, {
  useContext,
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import {
  localStorageRefreshTokenKey,
  localStorageAccessTokenKey,
  apiUrl,
  apiUrlSocket,
} from './constants';

interface NewTokens {
  newAccessToken: string;
  newRefreshToken: string;
}

const CORE_BACKEND_URL = process.env.REACT_APP_API_URL || '';

interface GraphQLError {
  message: string;
  extensions?: {
    code?: string;
    type?: string;
    [key: string]: unknown;
  };
}

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

interface GraphQLResponse<T> {
  data?: {
    refreshToken?: T;
  };
  errors?: GraphQLError[];
}

interface Context {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  logout(): Promise<void>;
  login: (accessToken: string, refreshToken: string) => Promise<void>;
  getNewTokens(): Promise<NewTokens | undefined>;
  axiosInstance: AxiosInstance;
}

interface DecodedToken {
  exp: number;
  iat: number;
}

interface Props {
  terminatingLink?: ApolloLink;
}

const REFRESH_BUFFER = 300000;
const TOKEN_CHECK_INTERVAL = 60000;

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  logout: async () => undefined,
  login: async () => undefined,
  getNewTokens: async () => undefined,
  axiosInstance: axios.create(),
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const promiseToObservable = (
  promise: Promise<NewTokens | undefined>
): Observable<unknown> =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const AuthProvider = ({
  children,
  terminatingLink,
}: PropsWithChildren<Props>) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const tokenCheckInterval = useRef<NodeJS.Timeout | null>(null);
  const isRefreshing = useRef(false);
  const refreshSubscribers = useRef<Array<(token: string) => void>>([]);

  const axiosInstance = useRef(
    axios.create({
      baseURL: CORE_BACKEND_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).current;

  const decodeToken = useCallback((token: string): DecodedToken | null => {
    try {
      return jwtDecode<DecodedToken>(token);
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  }, []);

  const getTokenExpirationTime = useCallback(
    (token: string): number | null => {
      const decoded = decodeToken(token);
      if (!decoded) return null;
      return decoded.exp * 1000;
    },
    [decodeToken]
  );

  const shouldRefreshToken = useCallback(
    (token: string): boolean => {
      const expirationTime = getTokenExpirationTime(token);
      if (!expirationTime) return true;
      return expirationTime - Date.now() <= REFRESH_BUFFER;
    },
    [getTokenExpirationTime]
  );

  const removeTokens = useCallback(() => {
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setAccessToken(null);
    setRefreshToken(null);
    setIsAuthenticated(false);

    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
      tokenCheckInterval.current = null;
    }
  }, []);

  const logout = useCallback(async (): Promise<void> => {
    removeTokens();
  }, [removeTokens]);

  const getNewTokens = useCallback(async (): Promise<NewTokens | undefined> => {
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    if (isRefreshing.current) {
      return new Promise((resolve) => {
        refreshSubscribers.current.push((token) => {
          resolve({newAccessToken: token, newRefreshToken: refreshToken});
        });
      });
    }

    isRefreshing.current = true;

    try {
      const response = await axiosInstance.post<
        GraphQLResponse<RefreshTokenResponse>
      >('', {
        query: `
          mutation RefreshToken($input: RefreshTokenInput!) {
            refreshToken(input: $input) {
              accessToken
              refreshToken
            }
          }
        `,
        variables: {
          input: {
            refreshToken,
          },
        },
      });

      const errors = response?.data?.errors;
      if (errors?.length) {
        throw new Error(errors[0]?.message ?? 'Unknown GraphQL error');
      }

      const result = response.data.data?.refreshToken;
      if (!result) {
        throw new Error('Refresh token response is invalid');
      }

      const newTokens = {
        newAccessToken: result.accessToken,
        newRefreshToken: result.refreshToken,
      };

      refreshSubscribers.current.forEach((cb) => cb(newTokens.newAccessToken));
      refreshSubscribers.current = [];

      return newTokens;
    } catch (error) {
      console.error('Error refreshing tokens:', error);
      await logout();
      return undefined;
    } finally {
      isRefreshing.current = false;
    }
  }, [refreshToken, logout, axiosInstance]);

  const httpLink = useMemo(() => createUploadLink({uri: apiUrl}), []);

  const authLink = useMemo(
    () =>
      setContext(() => ({
        headers: {
          ...(accessToken ? {authorization: `Bearer ${accessToken}`} : {}),
        },
      })),
    [accessToken]
  );

  const errorLink = useMemo(
    () =>
      onError(({graphQLErrors, operation, forward}) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            if (err.extensions?.type === 'TOKEN_EXPIRED') {
              return promiseToObservable(getNewTokens()).flatMap(() =>
                forward(operation)
              );
            }
            if (err.extensions?.type === 'TOKEN_INVALID') {
              void logout();
              break;
            }
          }
        }
      }),
    [getNewTokens, logout]
  );

  const wsLink = useMemo(
    () =>
      new GraphQLWsLink(
        createClient({
          url: apiUrlSocket,
          connectionParams: () => {
            if (!accessToken) {
              return {};
            }
            return {
              Authorization: `Bearer ${accessToken}`,
              'X-Client-Version': process.env.REACT_APP_VERSION || '1.0.0',
              'X-Request-ID': crypto.randomUUID?.() || Date.now().toString(),
            };
          },
          on: {
            connecting: () => {
              console.warn('Establishing secure WebSocket connection');
            },
            connected: () => {
              console.warn('Secure WebSocket connection established');
            },
            error: (err) => {
              console.error('WebSocket error:', err);
              setTimeout(() => {
                console.warn('Attempting to reconnect...');
              }, 1000);
            },
            closed: () => {
              console.warn('WebSocket connection closed');
            },
          },
          retryAttempts: 5,
          retryWait: (retries) =>
            new Promise((resolve) =>
              setTimeout(resolve, Math.min(1000 * Math.pow(2, retries), 30000))
            ),
          shouldRetry: () => {
            return true;
          },
        })
      ),
    [accessToken]
  );
  const splitLink = useMemo(
    () =>
      split(
        ({query}) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        terminatingLink || httpLink
      ),
    [terminatingLink, httpLink, wsLink]
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: errorLink.concat(authLink).concat(splitLink),
        connectToDevTools: process.env.NODE_ENV === 'development',
      }),
    [errorLink, authLink, splitLink]
  );

  const startTokenRefreshCheck = useCallback(() => {
    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
    }

    tokenCheckInterval.current = setInterval(async () => {
      if (accessToken && shouldRefreshToken(accessToken)) {
        try {
          const newTokens = await getNewTokens();
          if (newTokens) {
            setAccessToken(newTokens.newAccessToken);
            setRefreshToken(newTokens.newRefreshToken);
            localStorage.setItem(
              localStorageAccessTokenKey,
              newTokens.newAccessToken
            );
            localStorage.setItem(
              localStorageRefreshTokenKey,
              newTokens.newRefreshToken
            );
          }
        } catch (error) {
          console.error('Token refresh failed:', error);
          await logout();
        }
      }
    }, TOKEN_CHECK_INTERVAL);
  }, [accessToken, shouldRefreshToken, getNewTokens, logout]);

  const login = useCallback(
    async (newAccessToken: string, newRefreshToken: string) => {
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      setIsAuthenticated(true);
      localStorage.setItem(localStorageAccessTokenKey, newAccessToken);
      localStorage.setItem(localStorageRefreshTokenKey, newRefreshToken);
      startTokenRefreshCheck();
    },
    [startTokenRefreshCheck]
  );
  useEffect(() => {
    const initAuth = async () => {
      const storedAccessToken = localStorage.getItem(
        localStorageAccessTokenKey
      );
      const storedRefreshToken = localStorage.getItem(
        localStorageRefreshTokenKey
      );

      if (storedAccessToken && storedRefreshToken) {
        if (shouldRefreshToken(storedAccessToken)) {
          try {
            const newTokens = await getNewTokens();
            if (newTokens) {
              await login(newTokens.newAccessToken, newTokens.newRefreshToken);
            } else {
              removeTokens();
            }
          } catch (error) {
            console.error('Initial token refresh failed:', error);
            removeTokens();
          }
        } else {
          await login(storedAccessToken, storedRefreshToken);
        }
      }
    };

    void initAuth();

    return () => {
      if (tokenCheckInterval.current) {
        clearInterval(tokenCheckInterval.current);
      }
    };
  }, [login, getNewTokens, removeTokens, shouldRefreshToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        refreshToken,
        logout,
        login,
        getNewTokens,
        axiosInstance,
      }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
