import {useNavigate} from 'react-router-dom';

import {ReactComponent as GreenMoneyIcon} from '../../assets/icons/greenMoney.svg';
import {ReactComponent as RedMoneyIcon} from '../../assets/icons/redMoney.svg';
import Button from '../../components/tailwind/Button';
import {useUserAuth} from '../../utils/user';

const cardItems = [
  {
    icon: <GreenMoneyIcon />,
    title: 'Total Balance',
    amount: 'KES 0.00',
  },
  {icon: <RedMoneyIcon />, title: 'Available to payout', amount: 'KES 0.00'},
];

const Payouts = () => {
  const navigate = useNavigate();

  const {business} = useUserAuth();
  return (
    <div>
      <h1 className="font-bold text-xl  mt-4 md:text-3xl">Payouts</h1>
      <p className="mt-2 mb-3 md:mb-6 font-medium">
        Here is your most recent account summary!
      </p>

      <div className="flex gap-4 md:gap-10 ">
        {cardItems.map((item) => (
          <div
            key={item?.title}
            className="md:w-[40%] border w-[167px] p-3 border-dividerGrey md:gap-3 gap-2 rounded-lg md:p-5 flex flex-col justify-center h-[78px] md:h-[152px]">
            <div className="flex items-center gap-3">
              <p className="hidden md:block">{item?.icon}</p>
              <h1 className="md:text-base text-xs text-foggy font-medium">
                {item?.title}
              </h1>
            </div>

            <p className="font-bold text-sm md:text-3xl">{item?.amount}</p>
          </div>
        ))}
      </div>

      {!business && (
        <div className="py-6">
          <p className="font-semibold text-sm md:text-2xl py-3">
            Activate your account to start accepting payments & request a payout
          </p>
          <Button
            label="Complete account activation"
            onClick={() => navigate('/home/self-onboarding/business-info')}
            className="p-5 md:h-[60px] h-[48px] w-[253px] bg-smoothRed text-sm font-medium rounded-lg"
          />
        </div>
      )}
    </div>
  );
};

export default Payouts;
