import {buttonVariants} from '@cashiaApp/web-components';

import {ReactComponent as NotFoundIcon} from '../../../assets/404.svg';
import {ReactComponent as LogoIcon} from '../../../assets/logos/cashia_logo_pink.svg';
import {cn} from '../../../utils/reusableFunctions';

const Inactive = () => {
  return (
    <div className="flex flex-col justify-between h-screen w-screen sm:p-4 md:p-8 overflow-hidden overflow-y-scroll">
      <LogoIcon className="shrink-0 sm:w-8 sm:h-8 md:w-12 md:h-12" />
      <div className="flex flex-col gap-14 pb-10 w-full justify-center items-center">
        <NotFoundIcon className="sm:w-[299px] sm:h-[303px] md:w-[40svw] md:h-[40svh]" />
        <div className="flex flex-col w-full sm:gap-3 md:gap-8 sm:items-start md:items-center">
          <div className="flex flex-col sm:gap-1 md:gap-5 w-full sm:items-start md:items-center">
            <h2 className="font-semibold sm:text-2xl md:text-[50px] sm:text-left md:text-center">
              Oops...
            </h2>
            <h3 className="sm:text-[16px] md:text-[20px] font-medium">
              The payment link you used is deactivated!
            </h3>
          </div>
          <a
            href="https://www.cashia.com/"
            className={cn(
              buttonVariants({size: 'fit'}),
              '"w-fit sm:text-[12px] md:text-[16px]'
            )}>
            About Cashia
          </a>
        </div>
      </div>
      <p className="flex gap-[10px] text-[16px] text-[#757575] font-medium shrink-0">
        <span>Payment links</span>
        <div className="bg-[#DAD8D8] h-[26px] w-[1px]" />
        <a href="https://www.cashia.com/">
          Powered by <span className="text-smoothRed font-bold">Cashia</span>
        </a>
      </p>
    </div>
  );
};

export default Inactive;
