import React from 'react';
import ReactDOM from 'react-dom/client';
import {setKey} from 'react-geocode';
import {BrowserRouter} from 'react-router-dom';

import {EmailVerificationProvider} from './pages/auth/context/EmailVerificationContext';
import reportWebVitals from './reportWebVitals';
import App from './routes/routes';
import GraphQLProvider from './utils/apollo';
import AuthProvider from './utils/auth';
import {googleMapsKey} from './utils/constants';
import UserProvider from './utils/user';
import '@cashiaApp/web-components/dist/style.css';
import './index.css';

if (googleMapsKey) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  setKey(googleMapsKey);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <GraphQLProvider>
        <UserProvider>
          <BrowserRouter>
            <EmailVerificationProvider>
              <App />
            </EmailVerificationProvider>
          </BrowserRouter>
        </UserProvider>
      </GraphQLProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
