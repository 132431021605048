import React from 'react';
import {Outlet, useOutletContext} from 'react-router-dom';

import {OutletContextProps} from '../../layouts/MainLayout';

const LinksPage = () => {
  const {setHeaderComponent} = useOutletContext<OutletContextProps>();
  return (
    <div className="h-full">
      <Outlet context={{setHeaderComponent}} />
    </div>
  );
};

export default LinksPage;
