import {
  Button,
  toast,
  useMediaQuery,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@cashiaApp/web-components';
import {format} from 'date-fns';
import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';

import {ReactComponent as ActivateIcon} from '../../../assets/icons/activate.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as DeactivateIcon} from '../../../assets/icons/deactivate.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as EmptyIcon} from '../../../assets/icons/empty-sad-face.svg';
import {ReactComponent as LinkIcon} from '../../../assets/icons/link-arrow.svg';
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/right-arrow.svg';
import {ReactComponent as ProfileIcon} from '../../../assets/icons/user-square.svg';
import Spinner from '../../../components/tailwind/Spinner';
import {
  useGetBusinessQuery,
  useGetPaymentLinkQuery,
  useUpdatePaymentLinkMutation,
  UpdatePaymentLinkInput,
  useGetOrdersQuery,
} from '../../../graphql/generated';
import {OutletContextProps} from '../../../layouts/MainLayout';
import {cn} from '../../../utils/reusableFunctions';

interface LocationState {
  link: string;
}

export default function LinkDetails() {
  const {origin} = new URL(window.location.href);
  const location = useLocation();
  const linkId = (location.state as LocationState)?.link;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const {data: linkPayments} = useGetOrdersQuery({
    variables: {
      input: {
        cursor: {
          first: 10,
        },
        filter: {paymentLinkId: linkId},
      },
    },
  });

  const {data} = useGetPaymentLinkQuery({
    variables: {
      id: linkId,
    },
    skip: !linkId,
  });
  const {data: businessData} = useGetBusinessQuery();
  const readableLink = useMemo(() => {
    const newURL = new URL(`${origin}`);
    newURL.pathname = `/link/${
      businessData?.business?.name
        .toLocaleLowerCase()
        .trim()
        .replace(/\s+/g, '-') || ''
    }/${data?.paymentLink?.reference || ''}`;
    return newURL.toString();
  }, [businessData, data, origin]);

  const {setHeaderComponent} = useOutletContext<OutletContextProps>();
  useLayoutEffect(() => {
    if (!isDesktop) return;
    setHeaderComponent(() => (
      <div className="h-[75px] max-md:h-[35px] flex items-center gap-5 max-md:hidden">
        <p
          className="cursor-pointer font-medium"
          onClick={() => navigate('/payment-links')}>
          Payment links
        </p>
        <RightArrowIcon className="text-foggy font-medium" />
        <p className="text-foggy font-[500]">
          {data?.paymentLink?.title || ''}
        </p>
      </div>
    ));
    return () => {
      setHeaderComponent(undefined);
    };
  }, [setHeaderComponent, data, navigate, isDesktop]);

  const [updatePaymentLink, {loading}] = useUpdatePaymentLinkMutation();
  const [isActive, setIsActive] = useState<boolean | null>(null);

  useEffect(() => {
    if (data?.paymentLink?.active !== undefined) {
      setIsActive(Boolean(data.paymentLink.active));
    } else {
      setIsActive(null);
    }
  }, [data?.paymentLink?.active]);

  if (isActive === null) {
    return <Spinner />;
  }

  const handleDeactivate = async () => {
    try {
      await updatePaymentLink({
        variables: {
          input: {
            id: linkId,
            active: false,
          } as UpdatePaymentLinkInput,
        },
      });
      setIsActive(false);
    } catch (error) {
      toast.error('Failed to deactivate payment link.');
    }
  };

  const handleActivate = async () => {
    try {
      await updatePaymentLink({
        variables: {
          input: {
            id: linkId,
            active: true,
          },
        },
      });

      setIsActive(true);
    } catch (error) {
      toast.error('Failed to activate payment link.');
    }
  };

  return (
    <div className="flex w-full h-[95%] max-md:h-full overflow-y-scroll max-md:flex-col max-md:pb-[200px]">
      <div className="w-[50%] max-md:w-full lg:border-r-[1px] border-greyish max-md:px-1 pr-10 pt-8 max-md:pt-4 max-md:mb-5">
        <p className="font-[600] text-[24px] mb-1 break-words">
          {data?.paymentLink?.title || '---'}
        </p>
        <p className="font-[500] text-cashiaBlue mb-3">
          {data?.paymentLink?.cost?.currencyCode || 'KES'}{' '}
          {new Intl.NumberFormat('en-US').format(
            Number((data?.paymentLink?.cost?.amountInCents || 0) / 1000)
          )}
        </p>
        <p className="mb-5 font-[500] break-words">
          {data?.paymentLink?.description || ''}
        </p>

        <div
          className={cn(
            'mb-5 w-full min-h-[48px] rounded-[10px] border-greyish border-[1px] flex items-center',
            !isActive && 'opacity-40'
          )}>
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText(readableLink);
              toast.success('Link copied!');
            }}
            disabled={!isActive}
            className="h-[48px] cursor-pointer w-[10%] md:w-[7.5%] bg-offWhite flex items-center justify-center rounded-l-[10px] border-greyish border-r-[1px]">
            <div>
              <CopyIcon className="max-md:h-[20px]" />
            </div>
          </Button>

          <div className="flex-1 px-2 text-[14px] md:text-[16px] overflow-hidden truncate">
            <p>{readableLink}</p>
          </div>

          <Button
            onClick={() => window.open(readableLink, '_blank')}
            className="h-[48px] cursor-pointer w-[10%] md:w-[7.5%] bg-offWhite flex items-center justify-center rounded-r-[10px] border-greyish border-l-[1px]">
            <div>
              <LinkIcon className="max-md:h-[20px]" />
            </div>
          </Button>
        </div>

        <div className="mb-10 w-full h-[148px] rounded-[10px] border-[#DAD8D8] border-[1px] p-4 flex flex-col justify-between">
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Status:</p>
            {loading ? (
              <p />
            ) : data?.paymentLink?.active ? (
              <p className="text-green-400">Active</p>
            ) : (
              <p className="text-red-400">Deactivated</p>
            )}
          </div>
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Created on:</p>
            <p>
              {format(
                new Date((data?.paymentLink?.createdAt as string) || 0),
                'PPP'
              )}
            </p>
          </div>
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Link limit count:</p>
            <p>{data?.paymentLink?.limit || '-'}</p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-[300px] justify-between flex">
            <Button className="w-[105px] h-[48px] border-greyish border-[1px] rounded-[10px] bg-white text-black">
              <EditIcon className="mr-2" />
              Edit
            </Button>
            <Modal>
              <ModalTrigger asChild>
                {loading ? (
                  <div className="w-[160px] h-[48px] flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <Button
                    className={cn(
                      'w-[160px] h-[48px] rounded-[10px] text-white flex items-center justify-center',
                      isActive ? 'bg-darkRed' : 'bg-darkGreen'
                    )}>
                    {isActive ? (
                      <>
                        <DeactivateIcon className="mr-2" />
                        Deactivate
                      </>
                    ) : (
                      <>
                        <ActivateIcon className="mr-2" />
                        Activate
                      </>
                    )}
                  </Button>
                )}
              </ModalTrigger>
              <ModalContent className="lg:w-[25%] md:w-[40%] w-[80%]">
                <div>
                  <ModalHeader>
                    <ModalTitle>
                      {isActive
                        ? 'Deactivate this payment link'
                        : 'Activate this payment link'}
                    </ModalTitle>
                    <ModalDescription className="text-black">
                      {isActive
                        ? 'Customers will no longer be able to make a purchase using this link.'
                        : 'Customers will now be able to make a purchase using this link.'}
                    </ModalDescription>
                    <ModalDescription className="text-black">
                      {`You can choose to ${
                        isActive ? 'reactivate' : 'deactivate'
                      } this payment link at any time.`}
                    </ModalDescription>
                  </ModalHeader>
                </div>
                <div className="flex gap-x-3">
                  <ModalClose asChild>
                    <button className="border rounded-lg bg-white p-2.5">
                      Cancel
                    </button>
                  </ModalClose>
                  <ModalClose asChild>
                    <button
                      className={cn(
                        'border rounded-lg p-3 text-white',
                        isActive ? 'bg-darkRed' : 'bg-darkGreen'
                      )}
                      onClick={isActive ? handleDeactivate : handleActivate}>
                      {isActive ? 'Deactivate' : 'Activate'}
                    </button>
                  </ModalClose>
                </div>
              </ModalContent>
            </Modal>
          </div>
        </div>
      </div>
      <div className="w-[50%] px-10 pt-8 max-md:border-t-[1px] border-greyish max-md:px-1 max-md:w-full">
        <p className="font-[600] text-foggy">PAYMENTS</p>

        {linkPayments?.orders?.edges?.length ? (
          <div>
            {linkPayments?.orders?.edges?.map((item) => (
              <div
                key={item?.node?.id}
                className="flex items-center justify-between border-b-[1px] border-greyish py-[10px]">
                <div className="flex items-center gap-2 lg:gap-5 justify-between">
                  <ProfileIcon />
                  <div className="flex-grow">
                    <p className="text-[15px] font-[500]">
                      {item?.node?.firstName || 'Melissa'}{' '}
                      {item?.node?.lastName || 'Mukami'}
                    </p>
                    <p className="text-foggy text-[13px]">
                      {item?.node?.phoneNumber?.countryCode}{' '}
                      {item?.node?.phoneNumber?.number}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 lg:gap-14">
                  <div className="flex">
                    <p className="text-green-400 font-[500] text-end">
                      + {item?.node?.cost?.currencyCode}{' '}
                      {new Intl.NumberFormat('en-US').format(
                        Number(item?.node?.cost?.amountInCents / 1000)
                      )}
                    </p>
                  </div>
                  <div className="flex items-end">
                    {isDesktop ? (
                      <LinkIcon className="max-md:h-[20px]" />
                    ) : (
                      <ArrowIcon />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-full lg:h-[50%] flex justify-center mt-48 max-md:mt-10">
            <div className="flex text-center flex-col items-center">
              <EmptyIcon />
              <p className="my-5 text-[24px] font-[600]">
                It's lonely out here
              </p>
              <p className="w-[175px]">
                All your payments will be displayed here
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
